import React from 'react';
import { Table } from 'react-bootstrap';
import global_URL from '../../../Const/urls';
import { role } from '../../../Const/variables';

const ViewProduct = ({ product }) => {
  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-4">
      <Table bordered striped hover>
        <tbody>
          <tr>
            <td><strong>Product ID</strong></td>
            <td>{product.id}</td>
          </tr>
          <tr>
            <td><strong>Product Name</strong></td>
            <td>{product.pname}</td>
          </tr>

          {role==='admin'? ( <tr>
            <td><strong>Buying Price</strong></td>
            <td>{product.pcostprice}</td>
          </tr>):(null)}
         
          <tr>
            <td><strong>Selling Price</strong></td>
            <td>{product.pprice - product.pprice*(product.pdiscount/100) }</td>
          </tr>
          <tr>
            <td><strong>Category Name</strong></td>
            <td>{product.pCategoryName}</td>
          </tr>
          <tr>
            <td><strong>Color</strong></td>
            <td>{product.pcolor}</td>
          </tr>
          <tr>
            <td><strong>Size</strong></td>
            <td>{product.psize} {product.puom}</td>
          </tr>
          <tr>
            <td><strong>Quantity</strong></td>
            <td>{product.pquantity}</td>
          </tr>
          <tr>
            <td><strong>Product Image</strong></td>
            <td>
              <img
                src={`${global_URL}${product.image}`}
                alt={product.pname}
                style={{ width: '150px', height: '150px' }}
              />
            </td>
          </tr>
          <tr>
            <td><strong>Created At</strong></td>
            <td>{new Date(product.createdAt).toLocaleString()}</td>
          </tr>
          <tr>
            <td><strong>Updated At</strong></td>
            <td>{new Date(product.updatedOn).toLocaleString()}</td>
          </tr>
        </tbody>
      </Table>

      {/* <Button variant="primary" onClick={() => window.history.back()}>
        Back
      </Button> */}
    </div>
  );
};

export default ViewProduct;
