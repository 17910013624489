import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import global_URL from '../../../Const/urls';
import axioz from '../../../Services/axioz';
import {username,branch} from '../../../Const/variables';
import { addExpense } from '../../../Controllers/ExpensesController';

function CreateExpense() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState(''); 
  const [quantity, setQuantity] = useState(''); 
  const [productOptions, setProductOptions] = useState([]);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [touched, setTouched] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('name', name);
    formData.append('description', description);
    formData.append('recorderBy', username);
    formData.append('quantity', 1);
    formData.append('cost', quantity);
    formData.append('category', "expense");
    formData.append('branch', branch);

    try {
      const response = await addExpense(formData);

      // Check if the response is successful
      if (response.status === 200) {
        setMessage('Expense added successfully');
        
        // Reset form fields
        setName('');
        setDescription('');

        // Clear any existing error
        setError('');
      } else {
        setError('Failed to add expense');
      }
    } catch (err) {
      setError('Adding expense failed');
    }
  };

  useEffect(() => {
    const fetchExpensesR = async () => {
      try {
        const res = await axioz.get(`${global_URL}api/getexpensesR/getExpensesR`);
        setProductOptions(res.data); // Assuming response.data contains the categories\
        } catch (err) {
        console.error('Error fetching the expenses', err);
      }
    };

    fetchExpensesR();
  }, []);

  const handleBlur = (field) => () => {
    setTouched({ ...touched, [field]: true });
    setMessage('');
  };

  const getFieldClass = (field) => {
    const fieldValues = { name, quantity, description }; // Changed from password to phone
    return touched[field] && !fieldValues[field] ? 'is-invalid' : '';
  };


  return (
    <Container fluid className="d-flex min-vh-100">
      <Row className="justify-content-center align-items-center w-100">
        <Col xs={12} md={8} lg={6} className="px-4 py-2">
          <Card className="shadow-lg p-3">
            <Card.Body>
              <Card.Title className="text-center mb-4">Add Loss</Card.Title>
              <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formProduct" className="mb-3">
                  <Form.Label>Expense</Form.Label>
                  <Form.Control
                    as="select"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={handleBlur('name')}
                    className={`w-100 ${getFieldClass('name')}`}
                    required
                  >
                    <option value="">Select expense</option>
                        {productOptions.map((val) => (
                          <option key={val.id} value={val.name}>
                            {val.name}
                          </option>
                        ))}
                  </Form.Control>
                  {touched.name && !name && (
                    <Form.Text className="text-danger">Expense is required</Form.Text>
                  )}
                </Form.Group>

                <Form.Group controlId="formQuantity" className="mb-3">
                  <Form.Label>Cost</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter cost"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    onBlur={handleBlur('quantity')}
                    className={`w-100 ${getFieldClass('quantity')}`}
                    required
                  />
                  {touched.quantity && !quantity && <Form.Text className="text-danger">Cost is required</Form.Text>}
                </Form.Group>

                <Form.Group controlId="formDescription" className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    onBlur={handleBlur('description')}
                    className={`w-100 ${getFieldClass('description')}`}
                    required
                  />
                  {touched.description && !description && <Form.Text className="text-danger">Description is required</Form.Text>}
                </Form.Group>

                

                <Button variant="primary" type="submit" className="w-100">
                  Save
                </Button>

                {message && (
                  <div className="text-center mt-3">
                    <p className="text-success">{message}</p>
                  </div>
                )}

                {error && <p className="text-danger mt-3">{error}</p>}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default CreateExpense;
