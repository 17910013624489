
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import axiosi from '../../../Services/axioz';
import global_URL from '../../../Const/urls';
import  moment from 'moment'; 
import { submitStaffDailyReport } from '../../../Controllers/StaffPerformanceController';
import { username } from '../../../Const/variables';


// Dashboard Component
const DashboardStaffIndex = () => {
  // State to hold total sales
  const [totalDailySales, setTotalDailySales] = useState(null);
  const [totalDailySalesCount, setTotalDailySalesCount] = useState(null);
  const [totalWeeklySales, setTotalWeeklySales] = useState(null);
  const [totalWeeklySalesCount, setTotalWeeklySalesCount] = useState(null);
  const [totalMonthlySales, setTotalMonthlySales] = useState(null);
  const [totalMonthlySalesCount, setTotalMonthlySalesCount] = useState(null);
  const [totalAlltimeSales, setTotalAlltimeSales] = useState(null);
  const [totalAlltimeSalesCount, setTotalAlltimeSalesCount] = useState(null);


  const [showConfirmModal, setShowConfirmModal] = useState(false); 

  const [isReportSubmitted, setIsReportSubmitted] = useState(false); // New state for report submission
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));


  useEffect(() => {
    const lastSubmittedDate = localStorage.getItem(`lastSubmittedDate_${username}`);
    const isReportSubmittedForUser = localStorage.getItem(`isReportSubmitted_${username}`) === 'true';
    if (lastSubmittedDate === currentDate && isReportSubmittedForUser ) {
      setIsReportSubmitted(true);  // Report has already been submitted today
    } else {
      setIsReportSubmitted(false); // Reset for a new day
    }
  }, [currentDate, username]);


   // After report is submitted, store the submission date in localStorage
   useEffect(() => {
    if (isReportSubmitted) {
      localStorage.setItem(`lastSubmittedDate_${username}`, currentDate);  // Store the current date when submitted
      localStorage.setItem(`isReportSubmitted_${username}`, 'true'); // Store the submission status for the user  
    }
  }, [isReportSubmitted, currentDate, username]);

  const handleConfirmClick = () => {
    setShowConfirmModal(true);
  };

  // Fetch total sales from the API on component mount
  useEffect(() => {
    const fetchTotalDataStaff = async () => {
      //sales
      try {
        const response = await axiosi.get(`${global_URL}api/salesdashboard/getDailySalesStats/${username}`);
        setTotalDailySales(response.data.totalSales);  // Set total sales from API response
        setTotalDailySalesCount(response.data.salesCount); 
      } catch (error) {
        console.error('Error fetching total sales:', error);
        setTotalDailySales('!');
        setTotalDailySalesCount('!')
      }

      
// expenses 
      try {
        const response = await axiosi.get(`${global_URL}api/salesdashboard/getWeeklySalesStats/${username}`);
        setTotalWeeklySales(response.data.totalWeeklySales);  // Set total sales from API response
        setTotalWeeklySalesCount(response.data.totalWeeklyCount); 
      } catch (error) {
        console.error('Error fetching total expenses:', error);
        setTotalWeeklySales('!');
        setTotalWeeklySalesCount('!')
      }

        // expenses 
    try {
      const response = await axiosi.get(`${global_URL}api/salesdashboard/getMonthlySalesStats/${username}`);
      setTotalMonthlySales(response.data.totalMonthlySales);  // Set total sales from API response
        setTotalMonthlySalesCount(response.data.totalMonthlyCount); 
    } catch (error) {
      console.error('Error fetching total losses:', error);
      setTotalMonthlySales('!');
        setTotalMonthlySalesCount('!')
    }

    try {
      const response = await axiosi.get(`${global_URL}api/salesdashboard/getAllTimeSalesStats/${username}`);
      setTotalAlltimeSales(response.data.overallStats.totalSales);  // Set total sales from API response
        setTotalAlltimeSalesCount(response.data.overallStats.totalCount); 
    } catch (error) {
      console.error('Error fetching total losses:', error);
      setTotalAlltimeSales('!');
        setTotalAlltimeSalesCount('!')
    }

    };

fetchTotalDataStaff();
  },  [totalDailySales, totalWeeklySales, totalMonthlySales, totalAlltimeSales]);



  // Ensure charts are destroyed before unmounting
  const cardDataStaff= [
    {
      amount: totalDailySales !== null ? `${totalDailySales} TZS` : "0 TZS",  // Display fetched sales or loading state
      description: "Today Sales",
      count: totalDailySalesCount !== null ? `${totalDailySalesCount}` : "0",
      trend: "success",  // For upward trend
    },
    {
      amount: totalWeeklySales !== null? `${totalWeeklySales} TZS` : "0 TZS",
      description: "This Week Sales",
      count: totalWeeklySalesCount !== null ? `${totalWeeklySalesCount}` : "0",
      trend: "success",
    },
    {
      amount: totalMonthlySales !== null? `${totalMonthlySales} TZS` : "0 TZS",
      description: "This Month Sales",
      count: totalMonthlySalesCount !== null ? `${totalMonthlySalesCount}` : "0",
      trend: "success", // For downward trend
    },
    {
      amount: totalAlltimeSales !== null? `${totalAlltimeSales} TZS` : "0 TZS",
      description:"All Time Sales",
      count: totalAlltimeSalesCount !== null ? `${totalAlltimeSalesCount}` : "0",
      trend: "success",
    },
  ];


  return (
    <div>
      <Container fluid className="p-4">
        {/* Top Summary Cards */}
        <Row className="mb-4">
          {cardDataStaff.map((card, index) => (
            <Col md={3} key={index}>
              <Card className="p-3">
                <Card.Body>
                  <h5 className="mb-2">{card.amount}</h5>
                  <p className="text-muted">{card.description}</p>
                  <small className={`text-${card.trend}`}>{card.count} {card.trend === "success" ? "↑" : "↓"}</small>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>  

{/* Button adopting theme colors */}

        <button
        className={`btn ${isReportSubmitted ? 'btn-success' : 'btn-secondary'} position-fixed p-3`}
        style={{ bottom: '20px', right: '20px', zIndex: 1000 }}
        onClick={isReportSubmitted ? null : handleConfirmClick} // Disable if report is submitted
      >
        {isReportSubmitted ? "Today's Report is Submitted" : 'Submit Daily Report'}
      </button>


     
     <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to submit report?</p>
          <p className='text-secondary' >*Because once you have submitted you can't re-submit/re-do!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button 
  variant="primary"
  onClick={() => {
    // Safely handle null or undefined values by using an empty string if any variable is null or undefined
 

    submitStaffDailyReport(
     totalDailySales,totalDailySalesCount
    );

    setShowConfirmModal(false);
    setIsReportSubmitted(true);
  }}
>
  Confirm
</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DashboardStaffIndex;
