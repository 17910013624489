import jsPDF from 'jspdf';

export const generateReceipt = (cName, pname, pquantity, cost, receiptno, soldBy, totalAmount) => {
    const doc = new jsPDF();

    // Set up receipt details
    const receiptDetails = {
        receiptNumber: receiptno,
        customerName: cName,
        date: new Date().toLocaleDateString(),
        soldBy: soldBy,
        items: [
            { description: pname, price: pquantity * cost },
        ],
        totalAmount: totalAmount,
    };

    // Draw a border around the receipt
    doc.rect(5, 5, 200, 287); // x, y, width, height

    // Add centered Title with color
    doc.setFontSize(18);
    doc.setTextColor(204, 0, 0); // Red color for "Dorcas Company Limited"
    doc.text('Dorcas Company Limited', doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' });

    doc.setTextColor(0, 128, 0); // Green color for "Order"
    doc.text('Order', doc.internal.pageSize.getWidth() / 2, 30, { align: 'center' });

    // Reset text color to black for the rest of the receipt
    doc.setTextColor(0, 0, 0);

    // Add receipt number and date
    doc.setFontSize(12);
    doc.text(`Receipt No: ${receiptDetails.receiptNumber}`, 10, 40);
    doc.text(`Customer Name: ${receiptDetails.customerName}`, 10, 50);
    doc.text(`Date: ${receiptDetails.date}`, 10, 60);
    doc.text(`Seller: ${receiptDetails.soldBy}`, 10, 70);

    // Draw a horizontal line
    doc.line(10, 75, 200, 75); // from x1, y1 to x2, y2

    doc.text('Particulars',  doc.internal.pageSize.getWidth() / 2, 85,  { align: 'center' });

    doc.line(10, 90, 200, 90); // from x1, y1 to x2, y2

    // Draw headers for the grid
    doc.text('Description', 10, 95);
    
    // Align 'Amount' header to the rightmost side
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.text('Amount', pageWidth - doc.getTextWidth('Amount') - 10, 95);

    // Draw a horizontal line below headers
    doc.line(10, 98, 200, 98); // from x1, y1 to x2, y2

    // Add item list with grid lines
    let yPosition = 105;
    receiptDetails.items.forEach((item, index) => {
        // Description
        doc.text(`${index + 1}. ${item.description}`, 10, yPosition);
        
        // Price - Align price to the rightmost side
        const priceText = `TZS ${item.price}`;
        doc.text(priceText, pageWidth - doc.getTextWidth(priceText) - 10, yPosition);

        // Draw horizontal line between items
        yPosition += 10;
        doc.line(10, yPosition, 200, yPosition);
    });

    // Add total amount at the rightmost side
    doc.setFontSize(14);
    const totalText = `Total: TZS ${receiptDetails.totalAmount}`;
    const totalTextWidth = doc.getTextWidth(totalText);
    doc.text(totalText, pageWidth - totalTextWidth - 10, yPosition + 10);

    // Download the PDF
    doc.save(`Receipt_${receiptDetails.receiptNumber} - ${receiptDetails.customerName}.pdf`);
};
