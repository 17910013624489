import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import axiosi from '../../../Services/axioz';
import global_URL from '../../../Const/urls';

// Registering required chart.js components
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = () => {
  const [selectedPeriod, setSelectedPeriod] = useState('Weekly');
  const [chartData, setChartData] = useState(null);

  // Function to fetch weekly data
  const fetchWeeklyData = async () => {
    try {
      const response = await axiosi.get(`${global_URL}api/charts/lineChartWeekly`);
      setChartData(response.data);
    } catch (error) {
      console.error('Error fetching weekly data:', error);
    }
  };

  // Function to fetch monthly data
  const fetchMonthlyData = async () => {
    try {
      const response = await axiosi.get(`${global_URL}api/charts/lineChartMonthly`);
      setChartData(response.data);
    } catch (error) {
      console.error('Error fetching monthly data:', error);
    }
  };

  // Fetch data based on the selected period (Weekly or Monthly)
  useEffect(() => {
    if (selectedPeriod === 'Weekly') {
      fetchWeeklyData();
    } else {
      fetchMonthlyData();
    }
  }, [selectedPeriod]);

  // Handle dropdown change
  const handlePeriodChange = (e) => {
    setSelectedPeriod(e.target.value);
  };

  return (
    <div>
      <Row className="align-items-center">
        <Col>
          {/* <p>645 Orders on May 22</p> */}
        </Col>
        <Col md="auto">
          {/* Dropdown for selecting Weekly or Monthly */}
          <Form.Select
            value={selectedPeriod}
            onChange={handlePeriodChange}
            className="ml-auto" // Aligns to the right
          >
            <option value="Weekly">Weekly</option>
            <option value="Monthly">Monthly</option>
          </Form.Select>
        </Col>
      </Row>

      {/* Render chart only when chartData is available */}
      {chartData ? <Line id="line-chart" data={chartData} /> : <p>Loading chart...</p>}
    </div>
  );
};

export default LineChart;
