import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { addExpenseR } from '../../../Controllers/RegisterExpenseController';

function CreateExpenseR() {
  const [name, setName] = useState('');
  const [desc, setDesc] = useState(''); 
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [touched, setTouched] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', desc);

    try {
      const response = await addExpenseR(formData);

      // Check if the response is successful
      if (response.status === 200) {
        setMessage('Expense added successfully');
        
        // Reset form fields
        setName('');
        setDesc('');

        // Clear any existing error
        setError('');
      } else {
        setError('Failed to add expense');
      }
    } catch (err) {
      setError('Adding expense failed');
    }
  };

  const handleBlur = (field) => () => {
    setTouched({ ...touched, [field]: true });
  };

  const getFieldClass = (field) => {
    const fieldValues = { name, desc }; // Changed from password to phone

    return touched[field] && !fieldValues[field] ? 'is-invalid' : '';
  };


  return (
    <Container fluid className="d-flex min-vh-100">
      <Row className="justify-content-center align-items-center w-100">
        <Col xs={12} md={8} lg={6} className="px-4 py-2">
          <Card className="shadow-lg p-3">
            <Card.Body>
              <Card.Title className="text-center mb-4">Create Expense</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formname" className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter expense name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={handleBlur('name')}
                    className={`w-100 ${getFieldClass('name')}`}
                    required
                  />
                  {touched.name && !name && <Form.Text className="text-danger">Name is required</Form.Text>}
                </Form.Group>

                <Form.Group controlId="formDesc" className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter description"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    onBlur={handleBlur('desc')}
                    // className={`w-100 ${getFieldClass('desc')}`}
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Save
                </Button>

                {message && (
                  <div className="text-center mt-3">
                    <p className="text-success">{message}</p>
                  </div>
                )}

                {error && <p className="text-danger mt-3">{error}</p>}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default CreateExpenseR;
