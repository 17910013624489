import { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Alert, Card } from 'react-bootstrap';
import global_URL from '../../Const/urls';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [touched, setTouched] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState(null);

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle email change and automatically dismiss error if valid
  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    // Automatically dismiss error if email is valid
    if (validateEmail(emailValue)) {
      setEmailError(null);
    } else if (touched) {  // Only show error if the input has been touched
      setEmailError('Please enter a valid email address');
    }
  };

  // Handle blur to set touched state
  const handleBlur = () => {
    setTouched(true);

    // Validate email on blur
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError(null);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate before submitting
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
      return;
    }

    try {
      await axios.post(`${global_URL}api/forgotpassword/forgot-password` , { email } );
      setSuccess(true);
      setError(null);    // Clear any existing error
    } catch (err) {
      setError('Error sending password reset link');
      setSuccess(false);
    }
  };

  return (
    <Container fluid className="d-flex min-vh-100">
      <Row className="justify-content-center align-items-center w-100">
        <Col xs={12} md={8} lg={6}>

          {success && (
            <Alert variant="success" className='text-success' onClose={() => setSuccess(false)} dismissible>
              Password reset link sent to your email!
            </Alert>
          )}

          {error && (
            <Alert variant="danger" className='text-danger'  onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}

<Card className="shadow-lg p-3">
            <Card.Body>
              <Card.Title className="text-center mb-4">Forgot Password</Card.Title>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}  // Update email and check validity
                onBlur={handleBlur}            // Set touched state on blur
                isInvalid={touched && !!emailError}  // Show error only if touched and invalid
                required
              />
              {touched && emailError && (  // Show feedback if input is touched and invalid
                <Form.Control.Feedback type="invalid">
                  {emailError}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100 mt-3">
              Send Reset Link
            </Button>
          </Form>
          </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
