import axiosi from '../Services/axioz';
import global_URL from '../Const/urls';

const API_URL_add = `${global_URL}api/addorder/`;

const API_URL_update = `${global_URL}api/updateorder/`;

const API_URL_delete = `${global_URL}api/deleteorder/`;


// ADDING 
export const addOrder = async (formData) => {
    // Notice we're not converting the formData to JSON
    return await axiosi.post(`${API_URL_add}addOrder`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };


  // UPDATING 
export const updateOrder = async (id, formData) => {
  try{
    await axiosi.put(`${API_URL_update}updateOrder/${id}`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }catch(error){
    console.error('Error updating uom:', error);
  }
};

  // UPDATING ORDER STATUS
  export const updateOrderStatus = async (id, formData) => {
    try{
      await axiosi.put(`${API_URL_update}updateOrderStatuz/${id}`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }catch(error){
      console.error('Error updating uom:', error);
    }
  };


// DETEING 
export const handleOrderDeleteConfirm = async (selectedValue, vals, setVals, setShowDeleteModal) => {
  try {
    await axiosi.delete(`${API_URL_delete}deleteOrder/${selectedValue.id}`);
    setVals(vals.filter((val) => val.id !== selectedValue.id)); // Update products list
    setShowDeleteModal(false); // Close modal after deletion
  } catch (error) {
    console.error('Error deleting product:', error);
  }
};
  