import React from "react";
import { role } from "../../../../Const/variables";
import { goToCustomerProfilePage } from "../../../../Shared/Functions/navigate";

export const NavBar = (props) => {
  // Function to navigate to login page
  const goToLoginPage = () => {
    window.location.href = "/customer-register";
  };


  return (
    <div>
      <nav className="navbar navbar-expand-lg fixed-top" id="mainNav">
        <div className="container2">
          <a className="navbar-brand" href="#root">
            <img src="assets/img/LOGO-PNG-01.png" alt="..." className="circular-logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Menu
            <i className="fas fa-bars ms-1"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
              <li className="nav-item">
                <a className="nav-link" href="#services">
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#portfolio">
                  Products
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#team">
                  Team
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contact
                </a>
              </li>
              {/* Updated Sign In button */}
              <li className="nav-item">
             {role!=="customer"? 
                (  <button className="nav-link btn btn-link" onClick={goToLoginPage} style={{ textDecoration: 'none' }}>
                  SIGN IN
                </button>):
                (  <button className="nav-link btn btn-link" onClick={goToCustomerProfilePage} style={{ textDecoration: 'none' }}>
                  PROFILE
                </button>)
                }
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};
