import React from 'react';
import { useNavigate } from 'react-router-dom';

const MoreProductsButton = (props) => {
  const navigate = useNavigate();

  const goToShoppingPage = () => {
    // Navigate to the shopping page and pass props through the state
    
    navigate('/shopping', { state: { shopingdata: props } });
  };

  return (
    <button 
      className="btn btn-primary btn-xl text-uppercase rounded-pill p-3" 
      onClick={goToShoppingPage}
      style={{ paddingLeft: '60px', paddingRight: '60px' }} 
    >
      BIDHAA ZAIDI
    </button>
  );
};

export default MoreProductsButton;
