import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { updateExpenseR } from '../../../Controllers/RegisterExpenseController';

const UpdateExpenseR = ({ expense, onClose }) => {
  const [formData, setFormData] = useState({
    name:expense.name,
description: expense.description,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateExpenseR(expense.id, formData); // Call the update API
      onClose(); // Close modal after successful update
    } catch (error) {
      console.error('Error updating expense:', error);
      alert('Failed to update expense.');
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formName" className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Enter Name"
          />
        </Form.Group>

        <Form.Group controlId="formDesc" className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            placeholder="Enter Description"
          />
        </Form.Group>


        <Button variant="primary" type="submit">
          Update
        </Button>
      </Form>
    </div>
  );
};

export default UpdateExpenseR;
