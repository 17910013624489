import axiosi from '../Services/axioz';
import global_URL from '../Const/urls';

const API_URL_add = `${global_URL}api/topproductsreport/`;


// ADDING 
export const addTopproductsreport = async (formData) => {
    // Notice we're not converting the formData to JSON
    return await axiosi.post(`${API_URL_add}insertTopProductsReport`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };
