import React from 'react';
import { Table } from 'react-bootstrap';

const ViewUser = ({ user }) => {
  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-4">
      <Table bordered striped hover>
        <tbody>
          <tr>
            <td><strong>User ID</strong></td>
            <td>{user.id}</td>
          </tr>
          <tr>
            <td><strong>Username</strong></td>
            <td>{user.username}</td>
          </tr>
          <tr>
            <td><strong>First Name</strong></td>
            <td>{user.firstName}</td>
          </tr>
          <tr>
            <td><strong>Last Name</strong></td>
            <td>{user.lastName}</td>
          </tr>
          <tr>
            <td><strong>Phone</strong></td>
            <td>{user.phone}</td>
          </tr>
          <tr>
            <td><strong>Email</strong></td>
            <td>{user.email}</td>
          </tr>
          <tr>
            <td><strong>Address</strong></td>
            <td>{user.address}</td>
          </tr>
          <tr>
            <td><strong>Created At</strong></td>
            <td>{new Date(user.createdAt).toLocaleString()}</td>
          </tr>
          <tr>
            <td><strong>Updated At</strong></td>
            <td>{new Date(user.updatedOn).toLocaleString()}</td>
          </tr>
        </tbody>
      </Table>

      {/* <Button variant="primary" onClick={() => window.history.back()}>
        Back
      </Button> */}
    </div>
  );
};

export default ViewUser;
