import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import axiosi from '../../../Services/axioz';
import global_URL from '../../../Const/urls';
import { id, role, uzaId } from '../../../Const/variables';
import { goToChangePassword, goToProfilePage } from '../../../Shared/Functions/navigate';
import { userlogout } from '../../../Services/Auth/authService';
import MyOrders from '../Home/Components/SubCustomers/myorders';

const CustomerProfile = () => {
  const [profileData, setProfileData] = useState(null);
  const navigate = useNavigate(); // Initialize navigate

  // Redirect to home if uzaId is null
  useEffect(() => {
    if (uzaId === null) {
      navigate('/'); // Redirect to home page
    }
  }, [ navigate]);

  // Fetch profile data from API
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axiosi.get(`${global_URL}api/auth/getUser/${role === 'customer' ? uzaId : id}`);
        setProfileData(response.data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfile();
  }, []);

  // Check if profile data is still loading
  if (!profileData) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="mt-5 d-flex flex-column align-items-center">
      {/* Profile icon */}
      <FaUserCircle size={100} className="text-secondary mb-3" />

      {/* Full Name and Username */}
      <h2>{profileData.firstName}</h2>
      <p className="text-muted">@{profileData.username}</p>

      {/* Action Buttons */}
      <Row className="mt-4">
        <Col className="d-flex justify-content-around">
          <Button variant="primary" className="rounded-pill px-4 mx-2" onClick={goToProfilePage}>
            Edit Profile
          </Button>
          <Button variant="secondary" className="rounded-pill px-4 mx-2" onClick={goToChangePassword}>
            Change Password
          </Button>
        </Col>
      </Row>

      <MyOrders />

      <Row className="mt-4">
        <Col className="d-flex justify-content-around">
          <Button
            variant="secondary"
            className="rounded-pill px-4 mx-2"
            onClick={() => {
              userlogout(); // Call the logout function
              window.location = '/'; // Redirect to the homepage
            }}
          >
            Log Out
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomerProfile;
