import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import bcrypt from 'bcryptjs'; // Keep bcrypt for hashing
import { logout, updatePassword } from '../../Services/Auth/authService';
import global_URL from '../../Const/urls';
import { id, role, uzaId } from '../../Const/variables';
import { goToLogin } from '../../Shared/Functions/navigate';

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [storedPassword, setStoredPassword] = useState(''); // Store the hashed password from the server
  const [passwordMatch, setPasswordMatch] = useState(null); // Track password match state

  // Fetch stored hashed password for the user
  useEffect(() => {
    const fetchUserPassword = async () => {
      try {
        const response = await axios.get(`${global_URL}api/auth/getUser/${role==='customer'? uzaId:id}`);
        setStoredPassword(response.data.password); // Assume the server returns the hashed password

      } catch (error) {
        console.error('Error fetching user details:', error);
        setError('Failed to fetch user details');
      }
    };
    fetchUserPassword();
  }, []);

  // Check if new passwords match
  useEffect(() => {
    if (newPassword && confirmNewPassword) {
      setPasswordMatch(newPassword === confirmNewPassword);
    } else {
      setPasswordMatch(null);
    }
  }, [newPassword, confirmNewPassword]);

  // Handle form submission for updating the password
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Hash the old password entered by the user and compare with stored password
    try {
      // Check if old password matches the stored hashed password
      const isMatch = await bcrypt.compare(oldPassword, storedPassword);

      if (!isMatch) {
        setError('Old password is incorrect');
        return;
      }else{
        logout();
        goToLogin();
      }
    } catch (err) {
      setError('Error comparing passwords');
      return;
    }

    // Check if new passwords match
    if (!passwordMatch) {
      setError('New passwords do not match');
      return;
    }

    // Hash the new password before sending to the server
    try {

      const updatedData = {
        password: confirmNewPassword, // Update the password field with the hashed value
      };

      setError('');
      setMessage('');
      await updatePassword(role==='customer'? uzaId:id, updatedData);
      setMessage('Password updated successfully!');
    } catch (err) {
      setError('Failed to update password');
    }
  };

  return (
    <Container fluid className="d-flex min-vh-100">
      <Row className="justify-content-center align-items-center w-100">
        <Col xs={12} md={8} lg={6}>
          <Card className="shadow-lg p-3">
            <Card.Body>
              <Card.Title className="text-center mb-4">Change Password</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formOldPassword" className="mb-3">
                  <Form.Label>Old Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter old password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    className={`w-100 ${error.includes('Old password') ? 'is-invalid' : ''}`}
                    required
                  />
                  {error.includes('Old password') && (
                    <Form.Text className="text-danger">Old password is incorrect</Form.Text>
                  )}
                </Form.Group>

                <Form.Group controlId="formNewPassword" className="mb-3">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className={`w-100 ${passwordMatch === false ? 'is-invalid' : ''} ${passwordMatch === true ? 'is-valid' : ''}`}
                    required
                  />
                  {passwordMatch === false && <Form.Text className="text-danger">Passwords do not match</Form.Text>}
                </Form.Group>

                <Form.Group controlId="formConfirmNewPassword" className="mb-3">
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm new password"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    className={`w-100 ${passwordMatch === false ? 'is-invalid' : ''} ${passwordMatch === true ? 'is-valid' : ''}`}
                    required
                  />
                  {passwordMatch === false && <Form.Text className="text-danger">Passwords do not match</Form.Text>}
                </Form.Group>

                <Button variant="primary" type="submit" 
      
            className="w-100">
                  Change Password
                </Button>
                {message && <p className="text-success mt-3">{message}</p>}
                {error && <p className="text-danger mt-3">{error}</p>}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ChangePassword;
