import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Row, Col, Pagination, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from '../../../Services/axioz';
import global_URL from '../../../Const/urls';
import Viewer from './viewLoss'; // Import the ViewProduct component
import {branch} from '../../../Const/variables';
import { handleDeleteConfirm } from '../../../Controllers/ExpensesController';
import UpdateExpense from '../expenses/updateExpense';

const GetLosses = () => {
  const [loss, setLoss] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    category: '',
    quantity: '',
    recordedOn:''
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false); 



  const fetchLosses = async () => {
    try {
      const response = await axios.get(`${global_URL}api/getexpenses/getExpenses/${branch}`);
      setLoss(response.data);
    } catch (error) {
      console.error('Error fetching the uoms', error);
    }
  };

  useEffect(() => {

    fetchLosses();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
      setFilters({
        ...filters,
        [name]: value
      });
    setCurrentPage(1);
  };

  

  const filteredData = loss.filter((val) =>
    Object.keys(filters).every((key) =>
      val[key].toString().toLowerCase().includes(filters[key].toLowerCase())
    )
  );
  
  const indexOfLastValue = currentPage * itemsPerPage;
  const indexOfFirstValue = indexOfLastValue - itemsPerPage;
  const currentValue = filteredData.slice(indexOfFirstValue, indexOfLastValue);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEditClick = (val) => {
    setSelectedValue(val);
    setShowEditModal(true);
  };

  const handleViewClick = (val) => {
    setSelectedValue(val);
    setShowViewModal(true);
  };

  const handleDeleteClick = (val) => {
    setSelectedValue(val);
    setShowDeleteModal(true);
  };

  return (
    <div className="container mt-5 p-4">
      <Row className="align-items-center mb-3">
        <Col>
          <h2 style={{ fontWeight: 'bold' }}>Expenses</h2>
        </Col>
       
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>
              Product Name
              <Form.Control
                type="text"
                name="name"
                value={filters.name}
                onChange={handleFilterChange}
                placeholder="Filter by name"
              />
            </th>
            <th>
              Category
              <Form.Control
                type="text"
                name="category"
                value={filters.category}
                onChange={handleFilterChange}
                placeholder="Filter by category"
              />
            </th>
            <th>
            Quantity
              <Form.Control
                type="text"
                name="quantity"
                value={filters.quantity}
                onChange={handleFilterChange}
                placeholder="Filter by quantity"
              />
            </th>
            <th>
              Created At
               <Form.Control
                  type="text"
                  name="recordedOn"
                  value={filters.recordedOn}
                  onChange={handleFilterChange}
                  placeholder="YYYY-MM-DD HH:MM:SS"
                 />
             </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentValue.length > 0 ? (
            currentValue.map((data, index) => (
              <tr key={data.id}>
                <td>{indexOfFirstValue + index + 1}</td>
                <td>{data.name}</td>
                <td>{data.category}</td>
                <td>{data.quantity}</td>
                <td>{new Date(data.recordedOn).toLocaleString()}</td>
                <td className="d-flex align-items-center">
                  <Button
                    variant="primary"
                    className="me-2"
                    onClick={() => handleViewClick(data)} // Open view modal
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                  {data.category==="loss"?(null):(
                  <Button
                    variant="warning"
                    className="me-2"
                    onClick={() => handleEditClick(data)} // Open edit modal
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </Button>)}

   <Button variant="danger"
   onClick={() => handleDeleteClick(data)}
   >
     <FontAwesomeIcon icon={faTrash} />
   </Button>
                  
               
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center">
                Can't find your option, please try again.
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Pagination Controls */}
      <Pagination className="justify-content-center">
        {[...Array(totalPages)].map((_, pageIndex) => (
          <Pagination.Item
            key={pageIndex + 1}
            active={pageIndex + 1 === currentPage}
            onClick={() => handlePageChange(pageIndex + 1)}
            variant="primary"
            className={pageIndex + 1 === currentPage ? 'primary text-white' : 'primary'}
          >
            {pageIndex + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      {/* View Product Modal */}
      <Modal show={showViewModal} onHide={() => setShowViewModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>View Sale</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedValue && <Viewer expense={selectedValue} />}
        </Modal.Body>
      </Modal>

      {/* Edit Product Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedValue && <UpdateExpense expense={selectedValue} onClose={() =>
            {
              fetchLosses();
              setShowEditModal(false)
            }
            }/>}
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>
    Are you sure you want to delete this{' '}
    {selectedValue?.category === 'loss' ? 'loss' : 'expense'}?
  </p>
        {/* <p>Are you sure you want to delete this {selectedValue.category === 'loss' ? 'loss' : 'expense'}?</p> */}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() =>  handleDeleteConfirm(selectedValue, loss, setLoss, setShowDeleteModal)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GetLosses;
