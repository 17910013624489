import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Row, Col, Pagination, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import axios from '../../../Services/axioz';
import global_URL from '../../../Const/urls';
import ViewUser from '../Manager/viewManagers';

const GetCustomers = () => {
  const [staffs, setStaffs] = useState([]);
  const [filters, setFilters] = useState({
    username: '',
    firstName: '',
    phone: '',
    email: '',
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(`${global_URL}api/getcustomers/getCustomers`);
      setStaffs(response.data);
    } catch (error) {
      console.error('Error fetching the staffs', error);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
    setCurrentPage(1);
  };


  const filteredStaffs = staffs.filter((val) =>
    Object.keys(filters).every((key) =>
      val[key].toString().toLowerCase().includes(filters[key].toLowerCase())
    )
  );

  const indexOfLastStaff = currentPage * itemsPerPage;
  const indexOfFirstStaff = indexOfLastStaff - itemsPerPage;
  const currentStaff = filteredStaffs.slice(indexOfFirstStaff, indexOfLastStaff);
  const totalPages = Math.ceil(filteredStaffs.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };



  const handleViewClick = (staff) => {
    setSelectedStaff(staff);
    setShowViewModal(true);
  };

  return (
    <div className="container mt-5 p-4">
      <Row className="align-items-center mb-3">
        <Col>
          <h2 style={{ fontWeight: 'bold' }}>Customers</h2>
        </Col>
       
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>
              Username
              <Form.Control
                type="text"
                name="username"
                value={filters.username}
                onChange={handleFilterChange}
                placeholder="Filter by username"
              />
            </th>
            <th>
              First Name
              <Form.Control
                type="text"
                name="firstName"
                value={filters.firstName}
                onChange={handleFilterChange}
                placeholder="Filter by firstname"
              />
            </th>
        
            <th>
              Phone
              <Form.Control
                type="text"
                name="phone"
                value={filters.phone}
                onChange={handleFilterChange}
                placeholder="Filter by phone"
              />
            </th>
            <th>
              Email
              <Form.Control
                type="text"
                name="email"
                value={filters.email}
                onChange={handleFilterChange}
                placeholder="Filter by email"
              />
            </th>
    
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentStaff.length > 0 ? (
            currentStaff.map((staff, index) => (
              <tr key={staff.id}>
                <td>{indexOfFirstStaff + index + 1}</td>
                <td>{staff.username}</td>
                <td>{staff.firstName}</td>
                <td>{staff.phone}</td>
                <td>{staff.email}</td>
                <td className="d-flex align-items-center">
                  <Button
                    variant="primary"
                    className="me-2"
                    onClick={() => handleViewClick(staff)} // Open view modal
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                  {/* <Button
                    variant="warning"
                    className="me-2"
                    onClick={() => handleEditClick(staff)} // Open edit modal
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </Button>
                  <Button variant="danger">
                    <FontAwesomeIcon icon={faTrash} />
                  </Button> */}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center">
                Can't find your option, please try again.
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Pagination Controls */}
      <Pagination className="justify-content-center">
        {[...Array(totalPages)].map((_, pageIndex) => (
          <Pagination.Item
            key={pageIndex + 1}
            active={pageIndex + 1 === currentPage}
            onClick={() => handlePageChange(pageIndex + 1)}
            variant="primary"
            className={pageIndex + 1 === currentPage ? 'primary text-white' : 'primary'}
          >
            {pageIndex + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      {/* View Product Modal */}
      <Modal show={showViewModal} onHide={() => setShowViewModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>View Staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedStaff && <ViewUser user={selectedStaff} />}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GetCustomers;
