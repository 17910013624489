import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { addProducts } from '../../../Controllers/ProductsController';
import global_URL from '../../../Const/urls';
import axioz from '../../../Services/axioz';
// import {branch} from '../../../Const/variables';

function AddProducts() {
  const [pname, setPname] = useState('');
  const [pdescription, setPdescription] = useState('');
  const [pprice, setPprice] = useState('');
  const [pCategoryName, setpCategoryName] = useState('');
  const [categories, setCategories] = useState([]);
  const [pquantity, setPquantity] = useState('');
  const [psize, setPsize] = useState('');
  const [pcolor, setPcolor] = useState('');
  const [pcostprice, setPcostprice] = useState('');
  const [pdiscount, setPdiscount] = useState('');
  const [branch, setBranch] = useState('');
  const [puom, setPuom] = useState('');
  const [uoms, setUOMs] = useState([]);
  const [pvalue, setPvalue] = useState('');
  const [image, setImage] = useState(null);
  const [branchOptions, setBranchOptions] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [touched, setTouched] = useState({});
  const [imageError, setImageError] = useState(''); 

  const fetchBranches = async () => {
    try {
      const branchResponse = await axioz.get(`${global_URL}api/getbranches/getBranch`);
      setBranchOptions(branchResponse.data); // Assuming response.data contains the categories\
    } catch (error) {
      console.error('Error fetching the branches', error);
    }
  };

  const fetchCategoriesAndUOMs = async () => {
    try {
      const categoryResponse = await axioz.get(`${global_URL}api/getcategories/getCategories`);
      const uomResponse = await axioz.get(`${global_URL}api/getuoms/getUOM`);
      setCategories(categoryResponse.data); // Assuming response.data contains the categories
      setUOMs(uomResponse.data); 
    } catch (error) {
      console.error('Error fetching the uoms', error);
    }
  };

  useEffect(() => {
    
    fetchBranches();
    fetchCategoriesAndUOMs();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
      setImageError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!image) {
      setImageError('Please select an image');
      return;
    }

    const formData = new FormData();
    formData.append('pname', pname);
    formData.append('pdescription', pdescription);
    formData.append('pprice', pprice);
    formData.append('pCategoryName', pCategoryName);
    formData.append('pquantity', pquantity);
    formData.append('psize', psize);
    formData.append('pcolor', pcolor);
    formData.append('pcostprice', pcostprice);
    formData.append('pdiscount', pdiscount);
    formData.append('puom', puom);
    formData.append('pvalue', pvalue);
    if (image) formData.append('image', image);
    formData.append('branch', branch);

    try {
      const response = await addProducts(formData);

      if (response.status === 200) {
        setMessage('Product added successfully');
        // setPname('');
        // setPdescription('');
        // setPprice('');
        // setpCategoryName('');
        // setPquantity('');
        // setPsize('');
        // setPcolor('');
        // setPcostprice('');
        // setPdiscount('');
        // setPuom('');
        // setPvalue('');
        // setImage(null);
        // setImagePreview(null);
        // setError('');
      } else {
        setError('Failed to add product');
      }
    } catch (err) {
      setError('Adding product failed');
    }
  };

  const handleBlur = (field) => () => {
    setTouched({ ...touched, [field]: true });
  };

  const getFieldClass = (field) => {
    const fieldValues = { branch, pname, pdescription, pprice, pCategoryName, pquantity, psize, pcolor, pcostprice, pdiscount, puom, pvalue };
    return touched[field] && !fieldValues[field] ? 'is-invalid' : '';
  };

  return (
    <Container fluid className="d-flex min-vh-100">
      <Row className="justify-content-center align-items-center w-100">
        <Col xs={12} md={8} lg={8} className="px-4 py-2">
          <Card className="shadow-lg p-3">
            <Card.Body>
              <Card.Title className="text-center mb-4">Create Product</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                  <Form.Group controlId="formBranch" className="mb-3">
                  <Form.Label>Branch</Form.Label>
                  <Form.Control
                    as="select"
                    value={branch}
                    onChange={(e) => setBranch(e.target.value)}
                    onBlur={handleBlur('branch')}
                    className={`w-100 ${getFieldClass('branch')}`}
                    required
                  >
                    <option value="">Select branch</option>
                        {branchOptions.map((val) => (
                          <option key={val.id} value={val.name}>
                            {val.name}
                          </option>
                        ))}
                  </Form.Control>
                  {touched.branch && !branch && (
                    <Form.Text className="text-danger">Branch is required</Form.Text>
                  )}
                </Form.Group>

                  <Form.Group controlId="formPImage" className="mb-3">
                      <Form.Label>Product Image</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        className="w-100"
                      />
                      {imageError && <Form.Text className="text-danger">{imageError}</Form.Text>} {/* Show error if no image is selected */}
                      {imagePreview && (
                        <div className="mt-3">
                          <img 
                            src={imagePreview} 
                            alt="Preview" 
                            className="img-thumbnail" 
                            style={{ height: '120px', width: 'auto' }} 
                          />
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group controlId="formPName" className="mb-3">
                      <Form.Label>Product Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter product name"
                        value={pname}
                        onChange={(e) => setPname(e.target.value)}
                        onBlur={handleBlur('pname')}
                        className={`w-100 ${getFieldClass('pname')}`}
                        required
                      />
                      {touched.pname && !pname && <Form.Text className="text-danger">Product name is required</Form.Text>}
                    </Form.Group>

                    <Form.Group controlId="formPDescription" className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter description"
                        as="textarea" // This changes the input to a textarea
                        rows={4} // You can specify the number of visible rows
                        value={pdescription}
                        onChange={(e) => setPdescription(e.target.value)}
                        onBlur={handleBlur('pdescription')}
                        className={`w-100 ${getFieldClass('pdescription')}`}
                        required
                      />
                      {touched.pdescription && !pdescription && <Form.Text className="text-danger">Description is required</Form.Text>}
                    </Form.Group>

                    <Form.Group controlId="formPCostPrice" className="mb-3">
                      <Form.Label>Purchasing Price</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter buying price"
                        value={pcostprice}
                        onChange={(e) => setPcostprice(e.target.value)}
                        onBlur={handleBlur('pcostprice')}
                        className={`w-100 ${getFieldClass('pcostprice')}`}
                        required
                      />
                      {touched.pcostprice && !pcostprice && <Form.Text className="text-danger">Cost price is required</Form.Text>}
                    </Form.Group>

                    <Form.Group controlId="formPPrice" className="mb-3">
                      <Form.Label>Selling Price</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter selling price"
                        value={pprice}
                        onChange={(e) => setPprice(e.target.value)}
                        onBlur={handleBlur('pprice')}
                        className={`w-100 ${getFieldClass('pprice')}`}
                        required
                      />
                      {touched.pprice && !pprice && <Form.Text className="text-danger">Product price is required</Form.Text>}
                    </Form.Group>

                   
                  </Col>

                  <Col md={6}>

                   <Form.Group controlId="formPQuantity" className="mb-3">
                      <Form.Label>Quantity</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter quantity"
                        value={pquantity}
                        onChange={(e) => setPquantity(e.target.value)}
                        onBlur={handleBlur('pquantity')}
                        className={`w-100 ${getFieldClass('pquantity')}`}
                        required
                      />
                      {touched.pquantity && !pquantity && <Form.Text className="text-danger">Quantity is required</Form.Text>}
                    </Form.Group>
                  <Form.Group controlId="formPDiscount" className="mb-3">
                      <Form.Label>Discount</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter discount (Ex. 40%)"
                        value={pdiscount}
                        onChange={(e) => setPdiscount(e.target.value)}
                        onBlur={handleBlur('pdiscount')}
                        className={`w-100 ${getFieldClass('pdiscount')}`}
                        required
                      />
                      {touched.pdiscount && !pdiscount && <Form.Text className="text-danger">Discount is required</Form.Text>}
                    </Form.Group>

                  <Form.Group controlId="formCategoryName" className="mb-3">
                      <Form.Label>Category Name</Form.Label>
                      <Form.Control
                        as="select"
                        value={pCategoryName}
                        onChange={(e) => setpCategoryName(e.target.value)}
                        onBlur={handleBlur('pCategoryName')}
                        className={`w-100 ${getFieldClass('pCategoryName')}`}
                        required
                      >
                        <option value="">Select category</option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.name}>
                            {category.name}
                          </option>
                        ))}
                      </Form.Control>
                      {touched.pCategoryName && !pCategoryName && <Form.Text className="text-danger">Category name is required</Form.Text>}
                    </Form.Group>

                    <Form.Group controlId="formPColor" className="mb-3">
                      <Form.Label>Color</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter color"
                        value={pcolor}
                        onChange={(e) => setPcolor(e.target.value)}
                        onBlur={handleBlur('pcolor')}
                        className={`w-100 ${getFieldClass('pcolor')}`}
                        required
                      />
                      {touched.pcolor && !pcolor && <Form.Text className="text-danger">Color is required</Form.Text>}
                    </Form.Group>

                  <Form.Group controlId="formPSize" className="mb-3">
                      <Form.Label>Size</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter size"
                        value={psize}
                        onChange={(e) => setPsize(e.target.value)}
                        onBlur={handleBlur('psize')}
                        className={`w-100 ${getFieldClass('psize')}`}
                        required
                      />
                      {touched.psize && !psize && <Form.Text className="text-danger">Product size is required</Form.Text>}
                    </Form.Group>


                    <Form.Group controlId="formUOM" className="mb-3">
                      <Form.Label>Unit of Measurement (UOM)</Form.Label>
                      <Form.Control
                        as="select"
                        value={puom}
                        onChange={(e) => setPuom(e.target.value)}
                        onBlur={handleBlur('puom')}
                        className={`w-100 ${getFieldClass('puom')}`}
                        required
                      >
                        <option value="">Select UOM</option>
                        {uoms.map((uom) => (
                          <option key={uom.id} value={uom.name}>
                            {uom.name}
                          </option>
                        ))}
                      </Form.Control>
                      {touched.puom && !puom && <Form.Text className="text-danger">UOM is required</Form.Text>}
                    </Form.Group>

                    <Form.Group controlId="formPValue" className="mb-3">
                      <Form.Label>Product Value</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter value"
                        value={pvalue}
                        onChange={(e) => setPvalue(e.target.value)}
                        onBlur={handleBlur('pvalue')}
                        className={`w-100 ${getFieldClass('pvalue')}`}
                        required
                      />
                      {touched.pvalue && !pvalue && <Form.Text className="text-danger">Product value is required</Form.Text>}
                    </Form.Group>

                    
                  </Col>
                </Row>

                <Button variant="primary" type="submit" onClick={()=>{
                  setMessage('');
                }} className="mt-3 w-100">
                  Save
                </Button>
                {error && <p className="text-danger mt-2">{error}</p>}
                {message && <p className="text-success mt-2">{message}</p>}
              </Form>  
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AddProducts;
