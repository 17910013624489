import React from 'react';

const Footer = () => {
  return (
    <footer className="footer py-4">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 text-lg-start">
            Copyright &copy; Dorcas Company Limited 2024
          </div>
          <div className="col-lg-4 my-3 my-lg-0">
          <a
              className="btn btn-dark btn-social mx-2"
              href="https://www.instagram.com/dorcascompany/?hl=en"
              aria-label="Instagram"
              target="_blank"  // This opens the link in a new tab
              rel="noopener noreferrer"  // Security measure when using target="_blank"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              className="btn btn-dark btn-social mx-2"
              href="https://www.google.com/maps/dir/-6.2126966,35.795266/dorcas+company+limited+google+map/@-6.1783751,35.7251797,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x184de5f9962543a5:0x9402846300c6c6e8!2m2!1d35.7888003!2d-6.167502?entry=ttu&g_ep=EgoyMDI0MDkxOC4xIKXMDSoASAFQAw%3D%3D"
              aria-label="Google"
              target="_blank"  // This opens the link in a new tab
              rel="noopener noreferrer"  // Security measure when using target="_blank"
            >
              <i className="fab fa-google"></i>
            </a>
            <a
              className="btn btn-dark btn-social mx-2"
              href="https://www.facebook.com/p/Dorcas-Company-Limited-100063838838970/"
              aria-label="Facebook"
              target="_blank"  // This opens the link in a new tab
              rel="noopener noreferrer"  // Security measure when using target="_blank"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
           
          </div>
          <div className="col-lg-4 text-lg-end">
            <a className="link-dark text-decoration-none me-3" href="#!">
              Privacy Policy
            </a>
            <a className="link-dark text-decoration-none" href="#!">
              Terms of Use
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
