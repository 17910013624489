import axiosi from '../Services/axioz';
import global_URL from '../Const/urls';
import { branch, username } from '../Const/variables';

export const submitStaffDailyReport = async (daily,dailycount) => {
    try {
      const reportData = {
        daily,
        dailycount,
        username,
        branch,
      };
      

      // Send the data to the API via a POST request
      const response = await axiosi.post(`${global_URL}api/staffreports/submitStaffReportDaily`, reportData);
      
      if (response.status === 200) {
        // Handle success (you can show a success message or redirect)
      }
    } catch (error) {
      console.error('Error submitting report:', error);
    }  // Close modal after submission
  };