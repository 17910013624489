import React, { useState } from 'react';
import axiosi from '../../../../Services/axioz';
import global_URL from '../../../../Const/urls';

const ContactSection = () => {
  // State for form inputs
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  
  const API_URL_add = `${global_URL}api/addcontactus/`;

  // State for handling success or error messages
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Handle form input changes
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosi.post(`${API_URL_add}addContactus`, formData);

      if (response.status === 200) {
        // Show success message
        setSuccessMessage('Ujumbe umetumwa kwa mafanikio!');
        setErrorMessage('');
        // Reset the form
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: ''
        });

        // Automatically hide the success message after 5 seconds
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000); // 5 seconds delay
      } else {
        setErrorMessage('Kuna tatizo kwenye kutuma ujumbe. Tafadhali jaribu tena.');
        setSuccessMessage('');
      }
    } catch (error) {
      setErrorMessage('Kuna hitilafu kwenye kutuma ujumbe.');
      setSuccessMessage('');
    }
  };

  return (
    <section className="page-section" id="contact">
      <div className="container2">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Wasiliana Nasi</h2>
          <h2 className="section-heading text-uppercase">0764587738 / 0678006224</h2>
          <h2 className="section-heading text-uppercase">Au</h2>
          <h3 className="section-subheading text-muted">
            Tunaomba jina lako, namba na barua pepe, ili kukupata.
          </h3>
        </div>
        <form id="contactForm" onSubmit={handleSubmit}>
          <div className="row align-items-stretch mb-5">
            <div className="col-md-6">
              <div className="form-group">
                {/* Name input */}
                <input
                  className="form-control"
                  id="name"
                  type="text"
                  placeholder="Jina lako *"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                {/* Email address input */}
                <input
                  className="form-control"
                  id="email"
                  type="email"
                  placeholder="Barua pepe *"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group mb-md-0">
                {/* Phone number input */}
                <input
                  className="form-control"
                  id="phone"
                  type="tel"
                  placeholder="Namba yako *"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group form-group-textarea mb-md-0">
                {/* Message input */}
                <textarea
                  className="form-control"
                  id="message"
                  placeholder="Ujumbe wako *"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
            </div>
          </div>

          {/* Success/Error Messages */}
          {successMessage && (
            <div className="text-center text-success mb-3">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="text-center text-danger mb-3">
              {errorMessage}
            </div>
          )}

          {/* Submit Button */}
          <div className="text-center">
            <button className="btn btn-primary btn-xl text-uppercase rounded-pill p-3" type="submit">
              Tuma jumbe
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactSection;
