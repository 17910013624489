import React from 'react';

// Functional React Component
const Services = () => {
  return (
    <section className="page-section" id="services">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Huduma</h2>
          <h3 className="section-subheading text-muted">Tupo na wewe kila hatua</h3>
        </div>
        <div className="row text-center">
          <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fas fa-circle fa-stack-2x text-primary"></i>
              <i className="fas fa-person-digging fa-stack-1x fa-inverse"></i>
            </span>
            <h4 className="my-3">HATUA YA AWALI</h4>
            <p className="text-muted">
            mawe, cement, kokoto, Nondo na mchanga.
            </p>
          </div>
          <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fas fa-circle fa-stack-2x text-primary"></i>
              <i className="fas fa-trowel-bricks fa-stack-1x fa-inverse"></i>
            </span>
            <h4 className="my-3">WAKATI WA UJENZI</h4>
            <p className="text-muted">
            Cement, Wall putty, Gypsum na mikanda
            </p>
          </div>
          <div className="col-md-4">
            <span className="fa-stack fa-4x">
              <i className="fas fa-circle fa-stack-2x text-primary"></i>
              <i className="fas fa-building-circle-check fa-stack-1x fa-inverse"></i>
            </span>
            <h4 className="my-3">HATUA ZA KUMALIZIA</h4>
            <p className="text-muted">
            Rangi, tiles, Vitasa, Taa na Wallpapers na marembo mengine
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
