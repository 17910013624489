import React, {useEffect , useState} from "react";
import { NavBar } from "./Components/nav";
import Masthead from './Components/headerr';
import Services from './Components/services';
import Products from './Components/products';
import About from './Components/about';
import Team from './Components/team';
import Clients from './Components/clients';
import Contacts from './Components/contact';
import Footer from './Components/footer';
import axiosi from "../../../Services/axioz";
import global_URL from "../../../Const/urls";

const App = () => {
  const [landingPageData, setLandingPageData] = useState([]);


  // Fetch products
  const fetchProducts = async () => {
    try {
      const response = await axiosi.get(`${global_URL}api/getproducts/getProductz`);
      setLandingPageData(response.data);
    } catch (error) {
      console.error('Error fetching the products:', error);
    }
  };

  // Initial data fetch
  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div>
      <NavBar />
      <Masthead />
      <Services/>
      <Products data={landingPageData} />
    
      <About/>
      <Team/>
      <Clients/>
      <Contacts/>
      <Footer/>
    </div>
  );
};

export default App;
