import React from 'react';

const About = () => {
  const timelineItems = [
    {
      id: 1,
      date: '2009-2011',
      title: 'Tulianza Chini',
      description:
        '...!',
      imgSrc: 'assets/img/about/1.jpg',
      inverted: false,
    },
    {
      id: 2,
      date: 'Machi 2011',
      title: 'Tukafungu duka letu la kwanza!',
      description:
        '...!',
      imgSrc: 'assets/img/about/2.jpg',
      inverted: true,
    },
    {
      id: 3,
      date: 'Disemba 2015',
      title: 'Tukapanua duka zaidi',
      description:
        '...!',
      imgSrc: 'assets/img/about/3.jpg',
      inverted: false,
    },
    {
      id: 4,
      date: 'Julai 2020',
      title: 'Sasa tuna maduka mawili',
      description:
        'Moja likiwa Kisasa na lingine Jamuhuri!',
      imgSrc: 'assets/img/about/4.jpg',
      inverted: true,
    },
    {
      id: 5,
      title: 'Kua sehemu ya historia yetu!',
      isFinal: true,
      inverted: true,
    },
  ];

  return (
    <section className="page-section-about" id="about">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Kuhusu sisi</h2>
          <h3 className="section-subheading text-muted">Tulipotoka na tulipo sasa!</h3>
        </div>
        <ul className="timeline">
          {timelineItems.map((item) => (
            <li key={item.id} className={item.inverted ? 'timeline-inverted' : ''}>
              {!item.isFinal ? (
                <>
                  <div className="timeline-image">
                    <img className="rounded-circle img-fluid" src={item.imgSrc} alt="..." />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>{item.date}</h4>
                      <h4 className="subheading">{item.title}</h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">{item.description}</p>
                    </div>
                  </div>
                </>
              ) : (
                <div className="timeline-image">
                  <h4>
                    Kua sehemu
                    <br />
                    ya historia
                    <br />
                    yetu.
                  </h4>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default About;
