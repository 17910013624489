import React from 'react';
import { Table } from 'react-bootstrap';

const ViewOrder = ({ order }) => {
  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-4">
      <Table bordered striped hover>
        <tbody>
          <tr>
            <td><strong>Order ID</strong></td>
            <td>{order.id}</td>
          </tr>
          <tr>
            <td><strong>Customer Name</strong></td>
            <td>{order.firstName}</td>
          </tr>
          <tr>
            <td><strong>Name</strong></td>
            <td>{order.pname}</td>
          </tr>
         
          <tr>
            <td><strong>Color</strong></td>
            <td>{order.pcolor}</td>
          </tr>
          <tr>
            <td><strong>Quantity</strong></td>
            <td>{order.quantity}</td>
          </tr>
          <tr>
            <td><strong>Total Cost</strong></td>
            <td>{(order.pprice - (order.pprice*(order.pdiscount/100)))*(order.quantity)}</td>
          </tr>
          <tr>
            <td><strong>Ordered On</strong></td>
            <td>{new Date(order.orderedOn).toLocaleString()}</td>
          </tr>
      
        </tbody>
      </Table>
    </div>
  );
};

export default ViewOrder;
