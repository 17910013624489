import global_URL from "../Const/urls";
import axiosi from "../Services/axioz";


const API_URL_update = `${global_URL}api/updateuser/`;

const API_URL_delete = `${global_URL}api/deleteuser/`;

export const updateUserz = async (id, formData) => {
    try {
      await axiosi.put(`${API_URL_update}updateUser/${id}`, formData, {
        headers: {
            'Content-Type': 'application/json',
          },
      });
    } catch (error) {
      console.error('Error updating product:', error);
      throw error; // Throw error to catch it in the frontend for debugging.
    }
  };

// DETEING 
export const handleDeleteConfirm = async (selectedValue, vals, setVals, setShowDeleteModal) => {
    try {
      await axiosi.delete(`${API_URL_delete}deleteUser/${selectedValue.id}`);
      setVals(vals.filter((val) => val.id !== selectedValue.id)); // Update products list
      setShowDeleteModal(false); // Close modal after deletion
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };