import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { updateUOMs } from '../../../Controllers/UomsController';

const UpdateUOM = ({ uom, onClose }) => {
  const [formData, setFormData] = useState({
    name:uom.name,
symbols: uom.symbols,
cFoctor: uom.cFoctor
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateUOMs(uom.id, formData); // Call the update API
      onClose(); // Close modal after successful update
    } catch (error) {
      console.error('Error updating expense:', error);
      alert('Failed to update expense.');
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formName" className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Enter Name"
          />
        </Form.Group>

        <Form.Group controlId="formSymbols" className="mb-3">
          <Form.Label>Symbol</Form.Label>
          <Form.Control
            type="text"
            name="symbols"
            value={formData.symbols}
            onChange={handleInputChange}
            placeholder="Enter Symbol"
          />
        </Form.Group>

        <Form.Group controlId="formcFoctor" className="mb-3">
          <Form.Label>Convertion Factor</Form.Label>
          <Form.Control
            type="text"
            name="cFoctor"
            value={formData.cFoctor}
            onChange={handleInputChange}
            placeholder="Enter Convertion Factor"
          />
        </Form.Group>


        <Button variant="primary" type="submit">
          Update
        </Button>
      </Form>
    </div>
  );
};

export default UpdateUOM;
