import React from 'react';
import { Table } from 'react-bootstrap';

const ViewExpense = ({ expense }) => {
  if (!expense) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-4">
      <Table bordered striped hover>
        <tbody>
          <tr>
            <td><strong>Expense ID</strong></td>
            <td>{expense.id}</td>
          </tr>
          <tr>
            <td><strong>Name</strong></td>
            <td>{expense.name}</td>
          </tr>
          <tr>
            <td><strong>Category</strong></td>
            <td>{expense.category}</td>
          </tr>
          <tr>
            <td><strong>Description</strong></td>
            <td>{expense.description}</td>
          </tr>
          <tr>
            <td><strong>Quantity</strong></td>
            <td>{expense.quantity}</td>
          </tr>
          <tr>
            <td><strong>Total Cost</strong></td>
            <td>{(expense.cost)*(expense.quantity)}</td>
          </tr>
          <tr>
            <td><strong>Created At</strong></td>
            <td>{new Date(expense.recordedOn).toLocaleString()}</td>
          </tr>
      
        </tbody>
      </Table>

      {/* <Button variant="primary" onClick={() => window.history.back()}>
        Back
      </Button> */}
    </div>
  );
};

export default ViewExpense;
