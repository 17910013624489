import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../../Assets/LOGO-PNG-01.png';
import { login } from '../../../Services/Auth/authService'; // Import the login function
import { goToForgotPasswordPage } from '../../../Shared/Functions/navigate';
import '../../../App.css';

const CustomerLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setErrorMessage] = useState('');

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      // Trigger the animation after a short delay
      const timer = setTimeout(() => setIsVisible(true), 100);
      return () => clearTimeout(timer);
    }, []);

    const goToRegisterPage = () => {
        window.location.href = "/customer-register";
      };

      
   

    const handleLogin = async (e) => {
        e.preventDefault();
    
        try {
            const response = await login(username, password);
            setErrorMessage('');
            setMessage('Login successful!');
    
            const userRole = response.role; // Assuming the response contains the user's role
            
            if(userRole==="customer"){
                localStorage.setItem('fullname', JSON.stringify(response.firstName));
                localStorage.removeItem('id');
                localStorage.setItem('userId', JSON.stringify(response.id));
            }else{
                localStorage.setItem('username', JSON.stringify(response.uname));
                localStorage.setItem('branch', JSON.stringify(response.branch));
                localStorage.removeItem('userId');
                localStorage.setItem('id', JSON.stringify(response.id));
            }

            localStorage.setItem('role', JSON.stringify(response.role));
            localStorage.setItem('loggedinEmail', JSON.stringify(response.email));

          

            switch (userRole) {
                case 'admin':
                    window.location = "/admin";
                    break;
                case 'manager':
                    window.location = "/manager";
                    break;
                case 'staff':
                    window.location = "/staff";
                    break;
                default:
                    window.location = "/"; // Redirect to home or any default page
                    break;
            }
        } catch (error) {
            console.log(error);
            setErrorMessage('Invalid credentials');
        }
    };
    

    return (
        <Container fluid className="d-flex justify-content-center align-items-center min-vh-100 bg-primary">
            <Row className="w-70 shadow-lg bg-white rounded">
                <Col md={6} className="d-flex align-items-center justify-content-center">
                <img
          src={logo}
          alt="Dorcas Company LTD"
          className={`img-fluid p-3 animated-image ${isVisible ? 'visible' : ''}`}
      style={{ maxHeight: '300px', maxWidth: '300px', objectFit: 'contain' }}
        />
                </Col>
                <Col md={6} className="p-5">
                    <h2 className="text-center mb-4">Welcome Back!</h2>
                    <Form onSubmit={handleLogin}>
                        <Form.Group controlId="formUsername" className="mb-3">
                            <Form.Label className="text-start w-200">Email / Username</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Enter Email" 
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="rounded-pill"
                            />
                        </Form.Group>

                        <Form.Group controlId="formPassword" className="mb-3">
                            <Form.Label className="text-start w-200">Password</Form.Label>
                            <Form.Control 
                                type="password" 
                                placeholder="Enter Password" 
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="rounded-pill"
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicCheckbox" className="mb-3 d-flex justify-content-start">
                            <Form.Check type="checkbox" label="Remember Me" />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="w-100">
                            Login
                        </Button>

                  

                        <div className="d-flex justify-content-center">
  <button className="nav-link btn btn-link p-4" onClick={goToForgotPasswordPage} style={{ textDecoration: 'none' }}>
  Forgot Password?
  </button>
</div>

                        {message && (
                            <div className="text-center mt-2 text-success">
                                <p>{message}</p>
                            </div>
                        )}

{error && <p className="text-danger mt-2 text-center">{error}</p>}

<div className="d-flex justify-content-center">
  <button className="nav-link btn btn-link p-4" onClick={goToRegisterPage} style={{ textDecoration: 'none' }}>
    Don't have an account?
  </button>
</div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default CustomerLogin;
