
export const goToProfilePage = () => {
    window.location.href = "/profile";
  };

  export const goToCustomerProfilePage = () => {
    window.location.href = "/customer-profile";
  };

  export const goToForgotPasswordPage = () => {
    window.location.href = "/forgot-password";
  };

  export const goToChangePassword = () => {
    window.location.href = "/change-password";
  };

  export const goToLogin = () => {
    window.location.href = "/login";
  };



  
