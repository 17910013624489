import React from 'react';
import { Table } from 'react-bootstrap';

const ViewContactUs = ({ contact }) => {
  if (!contact) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-4">
      <Table bordered striped hover>
        <tbody>
          <tr>
            <td><strong>ID</strong></td>
            <td>{contact.id}</td>
          </tr>
          <tr>
            <td><strong>Customer Name</strong></td>
            <td>{contact.name}</td>
          </tr>
          <tr>
            <td><strong>Phone</strong></td>
            <td>{contact.phone}</td>
          </tr>
         
          <tr>
            <td><strong>Email</strong></td>
            <td>{contact.email}</td>
          </tr>
          <tr>
            <td><strong>Message</strong></td>
            <td>{contact.message}</td>
          </tr>
       
          <tr>
            <td><strong>Sent On</strong></td>
            <td>{new Date(contact.sent_On).toLocaleString()}</td>
          </tr>
      
        </tbody>
      </Table>
    </div>
  );
};

export default ViewContactUs;
