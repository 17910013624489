import axiosi from '../Services/axioz';
import global_URL from '../Const/urls';

const API_URL_add = `${global_URL}api/addsale/`;

const API_URL_update = `${global_URL}api/updatesale/`;

const API_URL_delete = `${global_URL}api/deletesale/`;


// ADDING 
export const addSale = async (formData) => {
    // Notice we're not converting the formData to JSON
    return await axiosi.post(`${API_URL_add}addSale`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };


  // UPDATING 
export const updateSale = async (id, formData) => {
  try{
    await axiosi.put(`${API_URL_update}updateSale/${id}`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }catch(error){
    console.error('Error updating uom:', error);
  }
};


// DETEING 
export const handleDeleteConfirm = async (selectedValue, vals, setVals, setShowDeleteModal) => {
  try {
    await axiosi.delete(`${API_URL_delete}deleteSale/${selectedValue.id}/${selectedValue.quantity}`);
    setVals(vals.filter((val) => val.id !== selectedValue.id)); // Update products list
    setShowDeleteModal(false); // Close modal after deletion
  } catch (error) {
    console.error('Error deleting product:', error);
  }
};
  