import React, { useState, useEffect } from 'react';
import { Table, Row, Col, Pagination, Modal, Form,Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from '../../../Services/axioz';
import global_URL from '../../../Const/urls';
import ViewContactUs from './viewContactUs';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const GetContactUs = () => {
  const [contactData, setContactData] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
    sent_On: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await axios.get(`${global_URL}api/getcontactus/getContactus`);
        setContactData(response.data);
      } catch (error) {
        console.error('Error fetching contact data', error);
      }
    };

    fetchContactData();
  }, []);

  const indexOfLastValue = currentPage * itemsPerPage;
  const indexOfFirstValue = indexOfLastValue - itemsPerPage;


  const filteredData = contactData.filter((val) =>
    Object.keys(filters).every((key) =>
      val[key]?.toString().toLowerCase().includes(filters[key].toLowerCase())
    )
  );
  

  const currentValue = filteredData.slice(indexOfFirstValue, indexOfLastValue);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleViewClick = (val) => {
    setSelectedValue(val);
    setShowViewModal(true);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
    setCurrentPage(1); // Reset to first page when filter changes
  };



  return (
    <div className="container mt-5 p-4">
      <Row className="align-items-center mb-3">
        <Col>
          <h2 style={{ fontWeight: 'bold' }}>Customer Messages</h2>
        </Col>
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>
              Name
              <Form.Control
                type="text"
                name="name"
                value={filters.name}
                onChange={handleFilterChange}
                placeholder="Filter by name"
              />
            </th>
            <th>
              Phone
              <Form.Control
                type="text"
                name="phone"
                value={filters.phone}
                onChange={handleFilterChange}
                placeholder="Filter by phone"
              />
            </th>
            <th>
              Email
              <Form.Control
                type="text"
                name="email"
                value={filters.email}
                onChange={handleFilterChange}
                placeholder="Filter by email"
              />
            </th>
            <th>
              Message
              <Form.Control
                type="text"
                name="message"
                value={filters.message}
                onChange={handleFilterChange}
                placeholder="Filter by message"
              />
            </th>
            <th>
              Sent On
              <Form.Control
                type="text"
                name="sent_On"
                value={filters.sent_On}
                onChange={handleFilterChange}
                placeholder="YYYY-MM-DD HH:MM:SS"
              />
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentValue.length > 0 ? (
            currentValue.map((data, index) => (
              <tr key={data.id}>
                <td>{indexOfFirstValue + index + 1}</td>
                <td>{data.name}</td>
                <td>{data.phone}</td>
                <td>{data.email}</td>
                <td>{data.message}</td>
                <td>{new Date(data.sent_On).toLocaleString()}</td>
                <td className="d-flex align-items-center">
                  <Button
                    variant="primary"
                    className="me-2"
                    onClick={() => handleViewClick(data)} // Open view modal
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                </td>
                {/* <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleViewClick(data)} // Open view modal
                  >
                    View
                  </button>
                </td> */}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center"> {/* Adjusted colspan to account for new column */}
                No contact entries found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Pagination Controls */}
      <Pagination className="justify-content-center">
        {[...Array(totalPages)].map((_, pageIndex) => (
          <Pagination.Item
            key={pageIndex + 1}
            active={pageIndex + 1 === currentPage}
            onClick={() => handlePageChange(pageIndex + 1)}
            variant="primary"
            className={pageIndex + 1 === currentPage ? 'primary text-white' : 'primary'}
          >
            {pageIndex + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      {/* View Contact Modal */}
      <Modal show={showViewModal} onHide={() => setShowViewModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>View Contact Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedValue && <ViewContactUs contact={selectedValue} />} {/* Assuming you have a Viewer for contact messages */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GetContactUs;
