import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Row, Col, Pagination, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from '../../../Services/axioz';
import global_URL from '../../../Const/urls';
import UpdateCategory from './updatecategories'; // Import the UpdateProduct component
import { handleDeleteConfirmCategory } from '../../../Controllers/CategoriesController';

const GetCategories = () => {
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    description: '',
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); 
  const [selectedValue, setSelectedValue] = useState(null);


  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${global_URL}api/getcategories/getCategories`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching the uoms', error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
    setCurrentPage(1);
  };

  const filteredCategories = categories.filter((product) =>
    Object.keys(filters).every((key) =>
      product[key].toString().toLowerCase().includes(filters[key].toLowerCase())
    )
  );
  

  const indexOfLastValue = currentPage * itemsPerPage;
  const indexOfFirstValue = indexOfLastValue - itemsPerPage;
  const currentValue = filteredCategories.slice(indexOfFirstValue, indexOfLastValue);
  const totalPages = Math.ceil(filteredCategories.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEditClick = (val) => {
    setSelectedValue(val);
    setShowEditModal(true);
  };

  const handleDeleteClick = (val) => {
    setSelectedValue(val);
    setShowDeleteModal(true);
  };


  return (
    <div className="container mt-5 p-4">
      <Row className="align-items-center mb-3">
        <Col>
          <h2 style={{ fontWeight: 'bold' }}>Categories</h2>
        </Col>
       
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>
              Name
              <Form.Control
                type="text"
                name="name"
                value={filters.name}
                onChange={handleFilterChange}
                placeholder="Filter by name"
              />
            </th>
            <th>
              Description
              <Form.Control
                type="text"
                name="description"
                value={filters.description}
                onChange={handleFilterChange}
                placeholder="Filter by description"
              />
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentValue.length > 0 ? (
            currentValue.map((data, index) => (
              <tr key={data.id}>
                <td>{indexOfFirstValue + index + 1}</td>
                <td>{data.name}</td>
                <td>{data.description}</td>
                <td className="d-flex align-items-center">
                  <Button
                    variant="warning"
                    className="me-2"
                    onClick={() => handleEditClick(data)} // Open edit modal
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </Button>
                  <Button variant="danger"
                  onClick={() => handleDeleteClick(data)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center">
                Can't find your option, please try again.
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Pagination Controls */}
      <Pagination className="justify-content-center">
        {[...Array(totalPages)].map((_, pageIndex) => (
          <Pagination.Item
            key={pageIndex + 1}
            active={pageIndex + 1 === currentPage}
            onClick={() => handlePageChange(pageIndex + 1)}
            variant="primary"
            className={pageIndex + 1 === currentPage ? 'primary text-white' : 'primary'}
          >
            {pageIndex + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      {/* Edit Product Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedValue && <UpdateCategory cat={selectedValue} onClose={() =>{
fetchCategories()
setShowEditModal(false)
          } }/>}
        </Modal.Body>
      </Modal>

      {/* Delete Confirmation Modal */}

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this category?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDeleteConfirmCategory(selectedValue, categories, setCategories, setShowDeleteModal)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GetCategories;
