import React from 'react';
import { Table } from 'react-bootstrap';

const ViewSale = ({ sale }) => {
  if (!sale) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-4">
      <Table bordered striped hover>
        <tbody>
          <tr>
            <td><strong>Sale ID</strong></td>
            <td>{sale.id}</td>
          </tr>
          <tr>
            <td><strong>Name</strong></td>
            <td>{sale.pname}</td>
          </tr>
          <tr>
            <td><strong>Quantity</strong></td>
            <td>{sale.quantity}</td>
          </tr>
        
          <tr>
            <td><strong>Sold On</strong></td>
            <td>{new Date(sale.createdOn).toLocaleString()}</td>
          </tr>
      
        </tbody>
      </Table>

      {/* <Button variant="primary" onClick={() => window.history.back()}>
        Back
      </Button> */}
    </div>
  );
};

export default ViewSale;
