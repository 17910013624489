import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import global_URL from '../../../Const/urls';
import axioz from '../../../Services/axioz';
import { username, branch } from '../../../Const/variables';
import { addExpense } from '../../../Controllers/ExpensesController';

function CreateLoss() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState(''); 
  const [quantity, setQuantity] = useState(''); 
  const [productOptions, setProductOptions] = useState([]);
  const [pprice, setPprice] = useState(0); // New state for price
  const [currentQuantity, setCurrentQuantity] = useState(0); // Track available quantity
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [touched, setTouched] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('name', name);
    formData.append('description', description);
    formData.append('recorderBy', username);
    formData.append('quantity', quantity);
    formData.append('cost', pprice); // Using the selected product price
    formData.append('category', "loss");
    formData.append('branch', branch);

    try {
      const response = await addExpense(formData);

      // Check if the response is successful
      if (response.status === 200) {
        setMessage('Loss added successfully');

        fetchProducts();
        
        // Reset form fields
        setName('');
        setDescription('');
        setQuantity('');
        setPprice(0); // Reset the price as well
        setCurrentQuantity(0); // Reset the quantity limit

        // Clear any existing error
        setError('');
      } else {
        setError('Failed to add loss');
      }
    } catch (err) {
      setError('Adding loss failed');
    }
  };

  const fetchProducts = async () => {
    try {
      const res = await axioz.get(`${global_URL}api/getproducts/getProducts/${branch}`);
      setProductOptions(res.data); // Assuming response.data contains the products
    } catch (err) {
      console.error('Error fetching the products', err);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleBlur = (field) => () => {
    setTouched({ ...touched, [field]: true });
    setMessage('');
  };

  const getFieldClass = (field) => {
    const fieldValues = { name, quantity, description };
    return touched[field] && !fieldValues[field] ? 'is-invalid' : '';
  };

  // New handler for product selection
  const handleProductChange = (e) => {
    const selectedProductName = e.target.value;
    setName(selectedProductName);

    // Find the selected product from productOptions and set its price and quantity
    const selectedProduct = productOptions.find(product => product.pname === selectedProductName);
    if (selectedProduct) {
      setPprice(selectedProduct.pcostprice); // Assuming 'pprice' exists in productOptions
      setCurrentQuantity(selectedProduct.pquantity); // Set available quantity
    }
  };

  return (
    <Container fluid className="d-flex min-vh-100">
      <Row className="justify-content-center align-items-center w-100">
        <Col xs={12} md={8} lg={6} className="px-4 py-2">
          <Card className="shadow-lg p-3">
            <Card.Body>
              <Card.Title className="text-center mb-4">Add Loss</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formProduct" className="mb-3">
                  <Form.Label>Product</Form.Label>
                  <Form.Control
                    as="select"
                    value={name}
                    onChange={handleProductChange} // Update the onChange handler
                    onBlur={handleBlur('name')}
                    className={`w-100 ${getFieldClass('name')}`}
                    required
                  >
                    <option value="">Select product</option>
                    {productOptions.map((val) => (
                      <option key={val.id} value={val.pname}>
                        {`${val.pname} - (${val.pquantity}) left`}
                      </option>
                    ))}
                  </Form.Control>
                  {touched.name && !name && (
                    <Form.Text className="text-danger">Product is required</Form.Text>
                  )}
                </Form.Group>

                <Form.Group controlId="formQuantity" className="mb-3">
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter quantity"
                    value={quantity}
                    // onChange={(e) => {
                    //   const enteredQuantity = e.target.value;
                    //   if (enteredQuantity === '' || parseInt(enteredQuantity) <= currentQuantity) {
                    //     setQuantity(enteredQuantity); // Allow input only within valid range
                    //   }
                    // }}

                    onChange={(e) => {
                      const enteredQuantity = e.target.value;
                
                      // Allow empty input (when backspacing) or update the value if it's within range
                      if (enteredQuantity === '') {
                        setQuantity('');  // Set quantity to an empty string when backspacing
                      } else if (parseInt(enteredQuantity, 10) <= currentQuantity) {
                        setQuantity(parseInt(enteredQuantity, 10));  // Set quantity within the valid range
                      } else {
                        setQuantity(currentQuantity);  // Set to max if exceeded
                      }
                    }}
                    onBlur={handleBlur('quantity')}

                    max={currentQuantity} // Set maximum quantity based on selected product
                    className={`w-100 ${getFieldClass('quantity')}`}
                    required
                  />
                  {touched.quantity && !quantity && (
                    <Form.Text className="text-danger">Quantity is required</Form.Text>
                  )}
                  {quantity > currentQuantity && (
                    <Form.Text className="text-danger">
                      Quantity cannot exceed {currentQuantity}
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group controlId="formDescription" className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    onBlur={handleBlur('description')}
                    className={`w-100 ${getFieldClass('description')}`}
                    required
                  />
                  {touched.description && !description && (
                    <Form.Text className="text-danger">Description is required</Form.Text>
                  )}
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Save
                </Button>

                {message && (
                  <div className="text-center mt-3">
                    <p className="text-success">{message}</p>
                  </div>
                )}

                {error && <p className="text-danger mt-3">{error}</p>}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default CreateLoss;
