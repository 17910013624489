import axiosi from '../axioz';
import global_URL from '../../Const/urls'

const API_URL = `${global_URL}api/auth/`;

export const register = async (username, fname, lname, password, role, email, status) => {
  return await axiosi.post(`${API_URL}register`, { username, fname, lname, password, role, email, status });
};

export const registerUsers = async (username, fname, mname, lname, role, email, phone, address, status, branch) => {
  return await axiosi.post(`${API_URL}registerUsers`, { username, fname, mname, lname, role, email, phone, address, status,branch });
};

export const registerCustomers = async (fullName, role, email, password, phone, status,) => {
  return await axiosi.post(`${API_URL}registerCustomers`, {  fullName, password, role, email, phone, status });
};

export const login = async (username, password) => {
  try {
    const response = await axiosi.post(`${API_URL}login`, { username, password });
    if (response.data.token) {
      localStorage.setItem('authToken', JSON.stringify(response.data));
 
      // console.log('Login Response:', response.data.role);
    }
    return response.data;
  } catch (error) {
    // console.error('Login Error:', error.response ? error.response.data : error.message);
    throw error; // Rethrow to be caught in the component
  }
};


// API service function for updating the user
export const updateUser = async (id, updatedData) => {
  try {
    await axiosi.put(`${API_URL}updateUser/${id}`, updatedData);
  } catch (error) {
    // console.error('Error updating user:', error);
    throw error;
  }
};

export const updatePassword = async (id, updatedData) => {
  try {
    await axiosi.put(`${API_URL}changePassword/${id}`, updatedData);
  } catch (error) {
    // console.error('Error updating user:', error);
    throw error;
  }
};



export const logout = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('id');
};

export const userlogout = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('role');
  localStorage.removeItem('userId');
};
export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('authToken'));
};
