import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { generateReceipt } from '../../../Shared/Functions/generateReceipts';

function GettingReciept({ data , onClose}) {
  const [cname, setCName] = useState(''); 
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [touched, setTouched] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
try{
    setError('')
    generateReceipt(cname,data.pname, data.quantity,(data.pprice - (data.pprice*(data.pdiscount/100))), data.id, data.soldBy ,(data.pprice - (data.pprice*(data.pdiscount/100)))*(data.quantity));
    setCName('');

    onClose();
}catch(e){
    console.log(e);
    setError('Unable to get Reciept')
}
   
   
}

const getFieldClass = (field) => {
    const fieldValues = { cname };
    return touched[field] && !fieldValues[field] ? 'is-invalid' : '';
  };

  const handleBlur = (field) => () => {
    setTouched({ ...touched, [field]: true });
    setMessage('');
  };



  return (
    <div>
              <Form onSubmit={handleSubmit}>

                <Form.Group controlId="formCName" className="mb-3">
                  <Form.Label>Cumoster Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Customer Name"
                    value={cname}
                    onChange={(e) => setCName(e.target.value)}
                    onBlur={handleBlur('cname')}
                    className={`w-100 ${getFieldClass('cname')}`}
                    required
                  />
                  {touched.cname && !cname && (
                    <Form.Text className="text-danger">Customer name is required</Form.Text>
                  )}
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Get Reciept
                </Button>

                {message && (
                  <div className="text-center mt-3">
                    <p className="text-success">{message}</p>
                  </div>
                )}

                {error && <p className="text-danger mt-3">{error}</p>}
              </Form>
              </div>
  );
}

export default GettingReciept;
