import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { updateUser } from '../../Services/Auth/authService';
import global_URL from '../../Const/urls';
import { id, role, uzaId } from '../../Const/variables';
import axiosi from '../../Services/axioz';




function UpdateUser() {
  const [username, setUsername] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [touched, setTouched] = useState({});

  // Fetch the existing user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axiosi.get(`${global_URL}api/auth/getUser/${role==='customer'? uzaId:id}`);
        const user = response.data;
        setUsername(user.username);
        setFname(user.firstName);
        setLname(user.lastName);
        setPhone(user.phone);
        setEmail(user.email);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setError('Failed to fetch user details');
      }
    };
    fetchUserDetails();
  }, []);

  // Handle form submission for updating the user
  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedData = {
      username,
      fname,
      lname,
      phone,
      email,
      role: role==="customer"? "customer": role , // Set the role explicitly or allow it to be updated
      status: 1, // Update the status if necessary
    };

    try {
        setError('');
        setMessage('');
      await updateUser(role==='customer'? uzaId:id, updatedData);
      setMessage('User updated!');
    //   navigate('/users'); // Navigate back to the user list or another page after successful update
    } catch (err) {
      setError('Failed to update user');
    }
  };

  // Manage field touch state for validation
  const handleBlur = (field) => () => {
    setTouched({ ...touched, [field]: true });
  };

  const getFieldClass = (field) => {
    const fieldValues = { username, fname, lname,phone, email };

    if (field === 'email') {
      return touched.email && !validateEmail(email) ? 'is-invalid' : '';
    }
    return touched[field] && !fieldValues[field] ? 'is-invalid' : '';
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <Container fluid className="d-flex min-vh-100">
      <Row className="justify-content-center align-items-center w-100">
        <Col xs={12} md={8} lg={6}>
          <Card className="shadow-lg p-3">
            <Card.Body>
              <Card.Title className="text-center mb-4">Profile</Card.Title>
              <Form onSubmit={handleSubmit}>
              {role==="admin" || role==='customer' ? (
  <Form.Group controlId="formUsername" className="mb-3">
  <Form.Label>Username</Form.Label>
  <Form.Control
    type="text"
    placeholder="Enter username"
    value={username}
    onChange={(e) => setUsername(e.target.value)}
    onBlur={handleBlur('username')}
    className={`w-100 ${getFieldClass('username')}`}
    required
  />
  {touched.username && !username && <Form.Text className="text-danger">Username is required</Form.Text>}
</Form.Group>
):(
  null
)}
                

                <Form.Group controlId="formFname" className="mb-3">
                  <Form.Label>{role==="customer"? 'Fulll Name': 'First Name'}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                    onBlur={handleBlur('fname')}
                    className={`w-100 ${getFieldClass('fname')}`}
                    required
                  />
                  {touched.fname && !fname && <Form.Text className="text-danger">First Name is required</Form.Text>}
                </Form.Group>

{role==="customer" ? (
  null
):(
<Form.Group controlId="formLname" className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter last name"
                    value={lname}
                    onChange={(e) => setLname(e.target.value)}
                    onBlur={handleBlur('lname')}
                    className={`w-100 ${getFieldClass('lname')}`}
                    required
                  />
                  {touched.lname && !lname && <Form.Text className="text-danger">Last Name is required</Form.Text>}
                </Form.Group>
)}
                
                <Form.Group controlId="formPhone" className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter phone"
                    maxLength="10"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    onBlur={handleBlur('phone')}
                    className={`w-100 ${getFieldClass('phone')}`}
                    required
                  />
              {touched.phone && !phone && <Form.Text className="text-danger">Phone is required</Form.Text>}
                </Form.Group>

                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={handleBlur('email')}
                    className={`w-100 ${getFieldClass('email')}`}
                    required
                  />
                  {touched.email && !validateEmail(email) && (
                    <Form.Text className="text-danger">Please enter a valid email address</Form.Text>
                  )}
                </Form.Group>

          
                <Button variant="primary" type="submit" className="w-100">
                  Update Profile
                </Button>
                {message && <p className="text-success mt-3">{message}</p>}
                {error && <p className="text-danger mt-3">{error}</p>}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default UpdateUser;
