import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { registerUsers } from '../../../Services/Auth/authService';
import {branch} from '../../../Const/variables';

function CreateStaff() {
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState(''); 
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [mname, setMname] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [touched, setTouched] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await registerUsers(username, fname, mname, lname, "staff", email, phone ,address, 1, branch); 
      setError('');
      setMessage('Staff added successfully');
      // Changed from password to phone
    } catch (err) {
      setMessage('');
      setError('Registration failed');
      console.error('An error occurred:', err);
    }
  };

  const handleBlur = (field) => () => {
    setTouched({ ...touched, [field]: true });
  };

  const getFieldClass = (field) => {
    const fieldValues = { username, phone, fname, lname, mname, email, address }; // Changed from password to phone

    if (field === 'email') {
      return touched.email && !validateEmail(email) ? 'is-invalid' : '';
    }
    return touched[field] && !fieldValues[field] ? 'is-invalid' : '';
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <Container fluid className="d-flex min-vh-100">
      <Row className="justify-content-center align-items-center w-100">
        <Col xs={12} md={8} lg={6} className="px-4 py-2">
          <Card className="shadow-lg p-3">
            <Card.Body>
              <Card.Title className="text-center mb-4">Create Staff</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formUsername" className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onBlur={handleBlur('username')}
                    className={`w-100 ${getFieldClass('username')}`}
                    required
                  />
                  {touched.username && !username && <Form.Text className="text-danger">Username is required</Form.Text>}
                </Form.Group>

                <Form.Group controlId="formFname" className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                    onBlur={handleBlur('fname')}
                    className={`w-100 ${getFieldClass('fname')}`}
                    required
                  />
                  {touched.fname && !fname && <Form.Text className="text-danger">First Name is required</Form.Text>}
                </Form.Group>

                <Form.Group controlId="formMname" className="mb-3">
                  <Form.Label>Middle Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter middle name"
                    value={mname}
                    onChange={(e) => setMname(e.target.value)}
                    onBlur={handleBlur('mname')}
                    // className={`w-100 ${getFieldClass('mname')}`}                  
                  />
                </Form.Group>

                <Form.Group controlId="formLname" className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter last name"
                    value={lname}
                    onChange={(e) => setLname(e.target.value)}
                    onBlur={handleBlur('lname')}
                    className={`w-100 ${getFieldClass('lname')}`}
                    required
                  />
                  {touched.lname && !lname && <Form.Text className="text-danger">Last Name is required</Form.Text>}
                </Form.Group>

                

                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={handleBlur('email')}
                    className={`w-100 ${getFieldClass('email')}`}
                    required
                  />
                  {touched.email && !validateEmail(email) && (
                    <Form.Text className="text-danger">Please enter a valid email address</Form.Text>
                  )}
                </Form.Group>

                <Form.Group controlId="formPhone" className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="tel" // Changed from password to tel
                    placeholder="Enter phone (Ex 0764...)"
                    maxLength="10"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    onBlur={handleBlur('phone')}
                    className={`w-100 ${getFieldClass('phone')}`} // Changed from password to phone
                    required
                  />
                  {touched.phone && !phone && <Form.Text className="text-danger">Phone number is required</Form.Text>} {/* Changed from password to phone */}
                </Form.Group>

                <Form.Group controlId="formAddress" className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    onBlur={handleBlur('address')}
                    className={`w-100 ${getFieldClass('address')}`}
                    required
                  />
                  {touched.address && !address && <Form.Text className="text-danger">Address is required</Form.Text>}
                </Form.Group>
                

                <Button variant="primary" type="submit" className="w-100">
                  Save
                </Button>

                {message && (
                  <div className="text-center mt-3">
                    <p className="text-success">{message}</p>
                  </div>
                )}


                {error && <p className="text-danger mt-3">{error}</p>}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default CreateStaff;
