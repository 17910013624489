import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { Button, Accordion, Dropdown, Offcanvas } from 'react-bootstrap';
import { FaBars, FaTimes, FaUser, FaPlus, FaUsers, FaBuilding, FaChevronDown, FaChevronUp, FaBox,FaRegAddressBook  } from 'react-icons/fa';
import logo from '../../Assets/LOGO-PNG-01.png';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../Services/Auth/authService';
import CreateManager from './Manager/createManager';
import AllManagers from './Manager/getMangers';
import CreateBranch from './Branch/addbranch';
import AllBranch from './Branch/getbranch';
import AllStaffs from '../Manager/staffs/getStaff';
import CreateProduct from '../Manager/products/addproducts';
import GetProduct from '../Manager/products/getProducts';
import Dashboard from '../components/dashboard/dashboard_Index';
import GetCustomers from './customers/getCustomers';
import CreateExpenseR from './ExpenseRegistration/addExpenseR';
import GetExpenseR from './ExpenseRegistration/getExpenseR';
import { goToChangePassword, goToProfilePage } from '../../Shared/Functions/navigate';
import GetInventories from '../Manager/inventories/getinventories';
import { role, username } from '../../Const/variables';
import GetContactUs from './ContactUs/getContactUs';

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isUsersOpen, setIsUsersOpen] = useState(false);
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isBranchesOpen, setIsBranchesOpen] = useState(false);
  const [isExpenseOpen, setIsExpenseOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState('dashboard');
  const [activeLink, setActiveLink] = useState('dashboard'); // New state to track active link
  const [showDrawer, setShowDrawer] = useState(false);
  const navigate = useNavigate();


  const handleDrawerClose = () => setShowDrawer(false);
  const handleDrawerShow = () => setShowDrawer(true);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleUsersSection = () => {
    setIsUsersOpen(!isUsersOpen);
  };

  const toggleBranchSection = () => {
    setIsBranchesOpen(!isBranchesOpen);
  };

  const toggleExpenseSection = () => {
    setIsExpenseOpen(!isExpenseOpen);
  };

  const navigateTo = (path) => {
    navigate(path);
    handleDrawerClose();
  };

  const handleComponentChange = (componentName, linkName) => {
    setActiveComponent(componentName);
    setActiveLink(linkName); // Set the active link
    handleDrawerClose();
  };

  const toggleProductsSection = () => {
    setIsProductsOpen(!isProductsOpen);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'dashboard':
        return <Dashboard />;
      case 'create-manager':
        return <CreateManager />;
      case 'all-managers':
        return <AllManagers />;
        case 'create-branch':
        return <CreateBranch />;
      case 'all-branches':
        return <AllBranch />;
        case 'create-expenseR':
          return <CreateExpenseR />;
        case 'all-expenseRes':
          return <GetExpenseR />;
      case 'all-staffs':
        return <AllStaffs />;
      case 'all-customers':
        return <GetCustomers />;
        case 'all-products':
        return <GetProduct/>;
      case 'create-products':
        return <CreateProduct/>;
        case 'inventories':
          return <GetInventories />;
          case 'contactus':
            return <GetContactUs />;
          
      default:
        return <Dashboard />;
    }
  };

  const SidebarContent = ({inDrawer = false})=>(
    <>
     <img 
          src={logo} 
          alt="Dorcas Company LTD" 
          className="img-fluid p-3" 
          style={{ maxHeight: '100px', maxWidth: '100px', objectFit: 'contain' }} 
        />
        {!isCollapsed && (
          <div className="d-flex justify-content-center mb-3">
            <h5 style={{ fontWeight: 'bold' }}>DORCAS COMPANY LTD</h5>
          </div>
        )}
        <Nav className="flex-column flex-grow-1">
          <Nav.Link 
            className={`d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'dashboard' ? 'text-primary bg-white rounded' : 'text-white'}`}
            onClick={() => handleComponentChange('dashboard', 'dashboard')}
          >
            <FaUser size={isCollapsed ? '24px' : '20px'} />
            {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Dashboard</span>}
            {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Dashboard</span>}
          </Nav.Link>

            {/* Expense Section */}
            <div className="d-flex flex-column">
            <Nav.Link 
              className={`d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'expenses' ? 'text-primary bg-white rounded' : 'text-white'}`}
              onClick={toggleExpenseSection}
              aria-controls="expenses-collapse"
              aria-expanded={isExpenseOpen}
              style={{ position: 'relative' }}
            >
              <div className={`d-flex ${isCollapsed ? 'flex-column align-items-center' : 'align-items-center justify-content-between'}`} style={{ width: '100%' }}>
                <div className="d-flex align-items-center">
                  <FaBuilding size={isCollapsed ? '24px' : '20px'} />
                  {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Expenses</span>}
                  {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Expenses</span>}
                </div>
                <span className="d-flex align-items-center">
                  {isExpenseOpen ? <FaChevronUp size="16px" /> : <FaChevronDown size="16px" />}
                </span>
              </div>
            </Nav.Link>
            <Accordion activeKey={isExpenseOpen ? '0' : null}>
              <Accordion.Collapse eventKey="0">
                <div id="users-collapse" className={`ms-${isCollapsed ? '3' : '4'}`}>
                  <Nav.Link onClick={() => handleComponentChange('all-expenseRes', 'all-expenseRes')} className={`${activeLink === 'all-expenseRes' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaBuilding size="16px" className="me-2" />
                    {!isCollapsed && <span>All Expenses</span>}
                  </Nav.Link>
                  <Nav.Link onClick={() => handleComponentChange('create-expenseR', 'create-expenseR')} className={`${activeLink === 'create-expenseR' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaPlus size="16px" className="me-2" />
                    {!isCollapsed && <span>Create Expense</span>}
                  </Nav.Link>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div>

            {/* Branch Section */}
            <div className="d-flex flex-column">
            <Nav.Link 
              className={`d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'branches' ? 'text-primary bg-white rounded' : 'text-white'}`}
              onClick={toggleBranchSection}
              aria-controls="branches-collapse"
              aria-expanded={isBranchesOpen}
              style={{ position: 'relative' }}
            >
              <div className={`d-flex ${isCollapsed ? 'flex-column align-items-center' : 'align-items-center justify-content-between'}`} style={{ width: '100%' }}>
                <div className="d-flex align-items-center">
                  <FaBuilding size={isCollapsed ? '24px' : '20px'} />
                  {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Branches</span>}
                  {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Branches</span>}
                </div>
                <span className="d-flex align-items-center">
                  {isBranchesOpen ? <FaChevronUp size="16px" /> : <FaChevronDown size="16px" />}
                </span>
              </div>
            </Nav.Link>
            <Accordion activeKey={isBranchesOpen ? '0' : null}>
              <Accordion.Collapse eventKey="0">
                <div id="users-collapse" className={`ms-${isCollapsed ? '3' : '4'}`}>
                  <Nav.Link onClick={() => handleComponentChange('all-branches', 'all-branches')} className={`${activeLink === 'all-branches' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaBuilding size="16px" className="me-2" />
                    {!isCollapsed && <span>All Branches</span>}
                  </Nav.Link>
                  <Nav.Link onClick={() => handleComponentChange('create-branch', 'create-branch')} className={`${activeLink === 'create-branch' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaPlus size="16px" className="me-2" />
                    {!isCollapsed && <span>Create Branch</span>}
                  </Nav.Link>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div>

          {/* Users Section */}
          <div className="d-flex flex-column">
            <Nav.Link 
              className={`d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'users' ? 'text-primary bg-white rounded' : 'text-white'}`}
              onClick={toggleUsersSection}
              aria-controls="users-collapse"
              aria-expanded={isUsersOpen}
              style={{ position: 'relative' }}
            >
              <div className={`d-flex ${isCollapsed ? 'flex-column align-items-center' : 'align-items-center justify-content-between'}`} style={{ width: '100%' }}>
                <div className="d-flex align-items-center">
                  <FaUsers size={isCollapsed ? '24px' : '20px'} />
                  {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">All Users</span>}
                  {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">All Users</span>}
                </div>
                <span className="d-flex align-items-center">
                  {isUsersOpen ? <FaChevronUp size="16px" /> : <FaChevronDown size="16px" />}
                </span>
              </div>
            </Nav.Link>
            <Accordion activeKey={isUsersOpen ? '0' : null}>
              <Accordion.Collapse eventKey="0">
                <div id="users-collapse" className={`ms-${isCollapsed ? '3' : '4'}`}>
                  <Nav.Link onClick={() => handleComponentChange('all-managers', 'all-managers')} className={`${activeLink === 'all-managers' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaUser size="16px" className="me-2" />
                    {!isCollapsed && <span>All Managers</span>}
                  </Nav.Link>
                  <Nav.Link onClick={() => handleComponentChange('create-manager', 'create-manager')} className={`${activeLink === 'create-manager' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaPlus size="16px" className="me-2" />
                    {!isCollapsed && <span>Create Manager</span>}
                  </Nav.Link>
                  <Nav.Link onClick={() => handleComponentChange('all-staffs', 'all-staffs')} className={`${activeLink === 'all-staffs' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaUser size="16px" className="me-2" />
                    {!isCollapsed && <span>All Staffs</span>}
                  </Nav.Link>
                  <Nav.Link onClick={() => handleComponentChange('all-customers', 'all-customers')} className={`${activeLink === 'all-customers' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaUser size="16px" className="me-2" />
                    {!isCollapsed && <span>All Customers</span>}
                  </Nav.Link>

                  
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div>

              {/* Products Section */}
              <div className="d-flex flex-column">
            <Nav.Link
              className={`text-white d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'products' ? 'text-primary bg-white rounded' : 'text-white'}`}
              onClick={toggleProductsSection}
              aria-controls="products-collapse"
              aria-expanded={isProductsOpen}
              style={{ position: 'relative' }}
            >
              <div className={`d-flex ${isCollapsed ? 'flex-column align-items-center' : 'align-items-center justify-content-between'}`} style={{ width: '100%' }}>
                <div className="d-flex align-items-center">
                  <FaUsers size={isCollapsed ? '24px' : '20px'} />
                  {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Products</span>}
                  {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Products</span>}
                </div>
                <span className="d-flex align-items-center">
                  {isProductsOpen ? <FaChevronUp size="16px" /> : <FaChevronDown size="16px" />}
                </span>
              </div>
            </Nav.Link>
            <Accordion activeKey={isProductsOpen ? '0' : null}>
              <Accordion.Collapse eventKey="0">
                <div id="products-collapse" className={`ms-${isCollapsed ? '3' : '4'}`}>
                  {/* <Nav.Link onClick={() => handleComponentChange('all-products', 'all-products')} className={`${activeLink === 'all-products' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaUser size="16px" className="me-2" />
                    {!isCollapsed && <span>All Products</span>}
                  </Nav.Link> */}
                  <Nav.Link onClick={() => handleComponentChange('create-products', 'create-products')} className={`${activeLink === 'create-products' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaPlus size="16px" className="me-2" />
                    {!isCollapsed && <span>Create Product</span>}
                  </Nav.Link>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div>

          <Nav.Link 
            className={`d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'inventories' ? 'text-primary bg-white rounded' : 'text-white'}`}
            onClick={() => handleComponentChange('inventories', 'inventories')}
          >
            <FaBox size={isCollapsed ? '24px' : '20px'} />
            {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Inventories</span>}
            {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Inventories</span>}
          </Nav.Link>
          <Nav.Link 
            className={`d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'contactus' ? 'text-primary bg-white rounded' : 'text-white'}`}
            onClick={() => handleComponentChange('contactus', 'contactus')}
          >
            <FaRegAddressBook  size={isCollapsed ? '24px' : '20px'} />
            {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Contact Us</span>}
            {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Contact Us</span>}
          </Nav.Link>
        </Nav>

        {/* Toggle button positioned at the bottom */}
        {/* <Button 
          onClick={toggleSidebar} 
          className="mt-auto mb-3" 
          style={{ width: '40px', height: '40px', alignSelf: 'center' }}
        >
          {isCollapsed ? <FaBars /> : <FaTimes />}
        </Button> */}

        <Dropdown>
        <div className="d-flex justify-content-center align-items-center">
  <Dropdown.Toggle
    variant="link"
    id="dropdown-basic"
    className="text-decoration-none text-center"
    style={{ color: 'white' }}
  >
    Ndugu: {username} : {role}
  </Dropdown.Toggle>
</div>

          <Dropdown.Menu align="end">
            <Dropdown.Item onClick={goToProfilePage}>Profile</Dropdown.Item>
            <Dropdown.Item onClick={goToChangePassword}>Change Password</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => {
              logout();
              navigateTo('/login');
            }}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
    </>
  )

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      {/* Hamburger button for small screens */}
      <div className="d-md-none position-fixed top-0 start-0 p-3 z-3">
        <button
          className="btn btn-primary"
          onClick={handleDrawerShow}
          style={{ width: '40px', height: '40px', padding: '0' }}
        >
          <FaBars size="20px" />
        </button>
      </div>

      {/* Regular sidebar for medium and larger screens */}
      <div
        className="bg-primary text-white vh-100 d-none d-md-flex flex-column p-2"
        style={{ width: isCollapsed ? '110px' : '250px', transition: 'width 0.3s' }}
      >
        {/* <SidebarContent /> */}
        <Offcanvas.Body>
          <SidebarContent/>
        </Offcanvas.Body>
      </div>

      {/* Drawer for small screens */}
      <Offcanvas show={showDrawer} onHide={handleDrawerClose} className="bg-primary text-white">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SidebarContent inDrawer={true} />
        </Offcanvas.Body>
      </Offcanvas>

      {/* Main content */}
      <div className="flex-grow-1">
        {renderComponent()}
      </div>
    </div>
  );
};

export default Sidebar;
