import React, { useEffect, useState, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const isAuthenticated = () => {
  return !!localStorage.getItem('authToken');
};

const getUserRole = () => {
  return JSON.parse(localStorage.getItem('role'));
};

const PrivateRoute = ({ element: Component, allowedRoles }) => {
  const [inactive, setInactive] = useState(false);
  const navigate = useNavigate();

  // Memoize the handleUserInteraction function to avoid unnecessary re-creations
  const handleUserInteraction = useCallback(() => {
    const storedRole = getUserRole();

    // If the user's role is not in the allowed roles, log them out
    if (isAuthenticated() && storedRole && !allowedRoles.includes(storedRole)) {
      localStorage.removeItem('authToken');
      // localStorage.removeItem('role');
      navigate('/login'); // Redirect to login
    }
  }, [allowedRoles, navigate]);

  useEffect(() => {
    // Add event listeners for mouse movement and touch events
    window.addEventListener('mousemove', handleUserInteraction);
    window.addEventListener('touchstart', handleUserInteraction);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('mousemove', handleUserInteraction);
      window.removeEventListener('touchstart', handleUserInteraction);
    };
  }, [handleUserInteraction]); // handleUserInteraction is now in the dependency array

  useEffect(() => {
    let timeout;

    const resetTimer = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setInactive(true); // Set inactive to true after 20 minutes
        localStorage.removeItem('authToken');
        // localStorage.removeItem('role');
        navigate('/login'); // Redirect to login after inactivity
      }, 600000); // 10 minutes
    };

    // Reset the timer on user interactions
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    
    // Initial timer set
    resetTimer();

    // Cleanup event listeners on component unmount
    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };
  }, [navigate]);

  const userRole = getUserRole();

  // Redirect to login page if inactive
  if (inactive) {
    return <Navigate to="/login" />;
  }

  // Check if the user is authenticated and has the appropriate role
  if (isAuthenticated() && allowedRoles.includes(userRole)) {
    return <Component />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
