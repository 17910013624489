import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import global_URL from '../../../Const/urls';
import axiosi from '../../../Services/axioz';

// Registering required chart.js components
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Revenue',
        data: [],
        backgroundColor: '#198754'
      }
    ]
  });

  // Fetch data from the API when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosi.get(`${global_URL}api/Charts/weeklySales`);
        const data = response.data;

        // Update the chart data state with API response
        setChartData(data);
      } catch (error) {
        console.error('Error fetching weekly sales data:', error);
      }
    };

    fetchData();

    // Clean up chart instance on component unmount
    return () => {
      Chart.getChart('bar-chart')?.destroy();
    };
  }, []);

  return <Bar id="bar-chart" data={chartData} />;
};

export default BarChart;
