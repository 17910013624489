import React, { useState } from 'react';
import Fuse from 'fuse.js'; // Import Fuse.js
import { role, uzaId } from '../../../../Const/variables';
import { addOrder } from '../../../../Controllers/OrdersController';
import { Button, Modal } from 'react-bootstrap';
import global_URL from '../../../../Const/urls';
import MoreProductsButton from './SubCustomers/moreProductsBtn';

const Products = (props) => {
  const [activeProduct, setActiveProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false); 
  const [searchTerm, setSearchTerm] = useState('');

  const goToLoginPage = () => {
    window.location.href = "/customer-register";
  };

  const incrementQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decrementQuantity = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const handleQuantityChange = (e) => {
    const value = Math.max(1, parseInt(e.target.value, 10));
    setQuantity(isNaN(value) ? 1 : value);
  };

  const openModal = (product) => {
    setActiveProduct(product);
    setShowModal(true);
  };

  const closeModal = () => {
    setError('');
    setMessage('');
    setActiveProduct(null);
    setShowModal(false);
    setQuantity(1);
  };

  const handleOrderCorfimedClick = () => {
    setShowConfirmModal(true);
  };

  const handleSubmit = async (pid) => {
    setError('');
    setMessage('');

    const formData = new FormData();
    formData.append('pId', pid);
    formData.append('uId', uzaId);
    formData.append('quantity', quantity);
    formData.append('status', 0);

    try {
      const response = await addOrder(formData);

      if (response?.status === 200) {
        setError('');
        setMessage('Order added successfully');
        setQuantity(1);
        setTimeout(() => {
          setMessage('');
          
        }, 5000); 
      } else {
        setError('Failed to add order. Please try again.');
        setQuantity(1);
     
      }
    } catch (err) {
      setError('An error occurred while adding the order. Please try again later.');
      setQuantity(1);
    }
  };

  // Fuse.js options for fuzzy searching
  const fuseOptions = {
    keys: ['pname','pdescription','pCategoryName'], // Field(s) to search by
    threshold: 0.5,  // Adjust this for more/less fuzzy matching
  };

  // Create Fuse.js instance with product data
  const fuse = new Fuse(props.data || [], fuseOptions);

  // Perform fuzzy search based on search term
  const filteredProducts = searchTerm
    ? fuse.search(searchTerm).slice(0, 9).map(result => result.item) // Limit the result to 9 products
    : props.data.slice(0, 9); // Show first 9 products when no search term is entered

  return (
    <section className="page-section bg-light" id="portfolio">
      <div className="container2">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">VIFAA VYA UJENZI</h2>
          <h3 className="section-subheading text-muted">Karibu kupata vifaa mbalimbali vya ujenzi imara wa jengo lako.</h3>
        </div>

        {/* Search Bar */}
        <div className="mb-4">
  <input
    type="text"
    placeholder="Tafuta bidhaa ndani ya Dorcas..."
    className="form-control"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)} // Update search term
    style={{
      height: '70px',          // Increases the height
      borderRadius: '17px',     // Makes corners rounded
      fontSize: '20px',         // Increases the font size
      border: 'none',           // Removes the border
      boxShadow: '0 0 5px rgba(0,0,0,0.2)' // Adds a subtle shadow for distinction
    }}
  />
</div>


        <div className="row">
          {filteredProducts.length > 0 ? (
            filteredProducts.map((item) => (
              <div className="col-lg-4 col-sm-6 mb-4" key={item.id}>
                <div className="portfolio-item">
                  <a
                    className="portfolio-link"
                    href="#portfolioModal"
                    onClick={() => openModal(item)}
                  >
                    <div className="portfolio-hover">
                      <div className="portfolio-hover-content">
                        <i className="fas fa-plus fa-3x"></i>
                      </div>
                    </div>
                    <img
                      className="img-fluid"
                      src={`${global_URL}${item.image}`}
                      alt={item.pname}
                    />
                  </a>
                  <div className="portfolio-caption">
                    <a
                      href="#portfolioModal"
                      onClick={() => openModal(item)}
                      className="portfolio-link"
                      data-bs-target="#portfolioModal"
                    >
                      <div className="portfolio-caption-heading text-muted">
                        {item.pname}
                      </div>
                    </a>
                    {item?.pdiscount === 0 ? (
                      <div className="portfolio-caption-subheading" style={{ fontWeight: 'bold' }}>
                        {Number(item?.pprice).toLocaleString()}/=
                      </div>
                    ) : (
                      <div>
                        <div className="portfolio-caption-subheading text-muted">
                          <span style={{ textDecoration: 'line-through' }}>
                            {Number(item?.pprice).toLocaleString()}/=
                          </span>
                        </div>
                        <div className="portfolio-caption-subheading" style={{ fontWeight: 'bold' }}>
                          {Number(item?.pprice - item?.pprice * (item?.pdiscount / 100)).toLocaleString()}/=
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              
            ))
          ) : (
            <div className="text-center">
              <p>Hakuna bidhaa kwa sasa!</p>
            </div>
          )}

  {/* //zaidi button  */}
  {filteredProducts.length > 0 ? (  <MoreProductsButton data={props.data} />):( <div></div> )}

        </div>

        {/* Modal */}
        {showModal && (
          <div
            className="portfolio-modal modal fade show"
            id="portfolioModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modal"
            style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="close-modal" onClick={closeModal} data-bs-dismiss="modal">
                  <img src="assets/img/close-icon.svg" alt="Close modal" />
                </div>
                <div className="container2">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="modal-body">
                        <div className="row">
                          {/* Left Column: Image */}
                          <div className="col-md-8 d-flex justify-content-center align-items-start">
                            <img
                              className="img-fluid"
                              src={`${global_URL}${activeProduct.image}`}
                              alt={activeProduct?.pname}
                              style={{ maxWidth: '100%', maxHeight: '400px', objectFit: 'cover' }}
                            />
                          </div>
                          {/* Right Column: Text, Cart Counter, and Button */}
                          <div className="col-md-4 d-flex flex-column justify-content-start align-items-start">
                            <h3 className="text-uppercase text-start text-muted">{activeProduct?.pname}</h3>
                            <h3 className="item-intro text-muted text-start">
                              {Number(activeProduct?.pprice - activeProduct?.pprice * (activeProduct?.pdiscount / 100)).toLocaleString()}
                            </h3>
                            <p className="text-start">{activeProduct?.pdescription}</p>
                            <ul className="list-inline text-start">
                              <li>
                                <strong>{activeProduct?.pdiscount}% OFF</strong>
                              </li>
                            </ul>
                            <div className="cart-quantity-container my-3 d-flex align-items-center">
                              <button className="btn btn-outline-secondary" onClick={decrementQuantity}>
                                -
                              </button>
                              <input
                                type="text"
                                className="form-control mx-3"
                                value={quantity}
                                onChange={handleQuantityChange}
                                min="1"
                                style={{ width: '70px', textAlign: 'center' }}
                              />
                              <button className="btn btn-outline-secondary" onClick={incrementQuantity}>
                                +
                              </button>
                            </div>
                            <h3 className="item-intro text-start">
                              {(Number(activeProduct?.pprice - activeProduct?.pprice * (activeProduct?.pdiscount / 100)) * Number(quantity)).toLocaleString()}/=
                            </h3>
                            <div className="mt-auto w-100">
                              {role !== "customer" ? (
                                <Button className="btn btn-primary w-100" onClick={goToLoginPage}>
                                  SignIn to Order
                                </Button>
                              ) : (
                                <Button className="btn btn-primary w-100"  onClick={() => handleOrderCorfimedClick()}>
                                  Order Now
                                </Button>
                              )}
                           <Button
  className="btn btn-danger w-100 mt-3"
  variant="danger"
  onClick={closeModal}
  data-bs-dismiss="modal"
>
                                Continue Browsing
                              </Button>
                            </div>
                            {error && <p className="text-danger">{error}</p>}
                            {message && <p className="text-success">{message}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

{/* order confirmation modal  */}

<Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to confirm this order?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button
            // variant="danger"
            onClick={()=> { handleSubmit(activeProduct?.id)
              setShowConfirmModal(false)

            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      </div>
    </section>
  );
};

export default Products;
