import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import {  registerCustomers } from '../../../Services/Auth/authService';

function RegisterCustomer() {
  const [confirmpassword, setConfirmpassword] = useState('');
  const [password, setPassword] = useState('');
  const [fname, setFname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [touched, setTouched] = useState({});
  const [passwordMatchError, setPasswordMatchError] = useState('');


  const goToLoginPage = () => {
    window.location.href = "/customerlogin";
  };

  useEffect(() => {
    if (touched.confirmpassword) {
      if (password !== confirmpassword) {
        setPasswordMatchError('Passwords do not match');
      } else {
        setPasswordMatchError('');
      }
    }
  }, [password, confirmpassword, touched.confirmpassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmpassword) {
      setPasswordMatchError('Passwords do not match');
      return;
    }

    try {
      await registerCustomers(fname,"customer",email,password, phone, 1);
      window.location = "/customerlogin";
    } catch (err) {
      setError('Registration failed');
    }
  };

  const handleBlur = (field) => () => {
    setTouched({ ...touched, [field]: true });
  };

  const getFieldClass = (field) => {
    const fieldValues = { password, confirmpassword, fname, phone, email };

    if (field === 'email') {
      return touched.email && !validateEmail(email) ? 'is-invalid' : '';
    }
    return touched[field] && !fieldValues[field] ? 'is-invalid' : '';
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <Container fluid className="d-flex min-vh-100">
      <Row className="justify-content-center align-items-center w-100">
        <Col xs={12} md={8} lg={6}>
          <Card className="shadow-lg p-3">
            <Card.Body>
              <Card.Title className="text-center mb-4">Register</Card.Title>
              <Form onSubmit={handleSubmit}>
                
                <Form.Group controlId="formFname" className="mb-3">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter full name"
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                    onBlur={handleBlur('fname')}
                    className={`w-100 ${getFieldClass('fname')}`}
                    required
                  />
                  {touched.fname && !fname && <Form.Text className="text-danger">Full Name is required</Form.Text>}
                </Form.Group>

                <Form.Group controlId="formPhone" className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter phone (Ex 0764...)"
                    maxLength="10"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    onBlur={handleBlur('phone')}
                    className={`w-100 ${getFieldClass('phone')}`}
                    required
                  />
                  {touched.phone && !phone && <Form.Text className="text-danger">Phone is required</Form.Text>}
                </Form.Group>

                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={handleBlur('email')}
                    className={`w-100 ${getFieldClass('email')}`}
                    required
                  />
                  {touched.email && !validateEmail(email) && (
                    <Form.Text className="text-danger">Please enter a valid email address</Form.Text>
                  )}
                </Form.Group>

                <Form.Group controlId="formPassword" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={handleBlur('password')}
                    className={`w-100 ${getFieldClass('password')}`}
                    required
                  />
                  {touched.password && !password && <Form.Text className="text-danger">Password is required</Form.Text>}
                </Form.Group>

                <Form.Group controlId="formConfirmPassword" className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmpassword}
                    onChange={(e) => setConfirmpassword(e.target.value)}
                    onBlur={handleBlur('confirmpassword')}
                    className={`w-100 ${getFieldClass('confirmpassword')}`}
                    required
                  />
                  {passwordMatchError && <Form.Text className="text-danger">{passwordMatchError}</Form.Text>}
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Register
                </Button>
                {error && <p className="text-danger mt-3">{error}</p>}

                <div className="d-flex justify-content-center">
  <button className="nav-link btn btn-link p-4" onClick={goToLoginPage} style={{ textDecoration: 'none' }}>
    Already have an account?
  </button>
</div>

              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default RegisterCustomer;
