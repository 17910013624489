import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { updateSale } from '../../../Controllers/SalesController';

const UpdateSales = ({ sale, onClose }) => {
  const [formData, setFormData] = useState({
    quantity: sale.quantity,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateSale(sale.id, formData); // Call the update API
      onClose(); // Close modal after successful update
    } catch (error) {
      console.error('Error updating sale:', error);
      alert('Failed to update sale.');
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formQuantity" className="mb-3">
          <Form.Label>Quantity</Form.Label>
          <Form.Control
            type="number"
            name="quantity"
            value={formData.quantity}
            onChange={handleInputChange}
            placeholder="Enter Quatity"
          />
        </Form.Group>


        <Button variant="primary" type="submit">
          Update Sale
        </Button>
      </Form>
    </div>
  );
};

export default UpdateSales;
