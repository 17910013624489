import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Views/Client/Home/index';
import AdminPage from './Views/Admin/Dashboard/dashboard'; // Replace with actual sub-route component
import ManagerPage from './Views/Manager/dashboard';
import StaffPage from './Views/Staff/dashboard';
import Login from './Views/Auth/login';
import Register from './Views/Auth/register';
// import Home from './Views/Client/Home/App';
import PrivateRoute from './Services/Auth/PrivateRoute';
import UpdateProduct from './Views/Manager/products/updateproducts';
import ViewProduct from './Views/Manager/products/viewproducts'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Style/theme.scss';
import CustomerLogin from './Views/Client/Auth/customerLogin';
import RegisterCustomer from './Views/Client/Auth/registerCustomers';
import AllProducts from './Views/Client/Shop/shopIndex';
import UpdateUser from './Views/Auth/updateUsers';
import ChangePassword from './Views/Auth/changePassword';
import CustomerProfile from './Views/Client/Auth/customerProfile';
import ForgotPassword from './Views/Auth/forgotPassword';
import ResetPassword from './Views/Auth/resetpassword';
import NotFoundPage from './Views/components/reused/notfound';



function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Home/>} />

      {/* <Route path="/" element={<Home />} /> */}
        <Route path="/login" element={<Login  />} />
        <Route path="/customerlogin" element={<CustomerLogin />} /> 
        <Route path="/shopping" element={<AllProducts />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<UpdateUser/>}/>
        <Route path="/customer-profile" element={<CustomerProfile/>}/>
        <Route path="/change-password" element={<ChangePassword/>}/>
        <Route path="/forgot-password" element={<ForgotPassword/>}/>
        <Route path="/reset-password/:id" element={<ResetPassword/>} />
        <Route path="/customer-register" element={<RegisterCustomer />} />
        <Route path="/update-product/:productId" element={<UpdateProduct />} />
        <Route path="/view-product/:productId" element={<ViewProduct />} />

        {/* Authenticated ROUTES ? */}
        <Route path="/admin" element={<PrivateRoute element={AdminPage} allowedRoles={['admin']} />}/>
        <Route path="/manager" element={<PrivateRoute element={ManagerPage} allowedRoles={['manager']} />} />
        <Route path="/staff" element={<PrivateRoute element={StaffPage} allowedRoles={['staff']} />} />
        <Route path="/" element={<PrivateRoute element={Home} allowedRoles={['customer']} />} />

        {/* Catch-all route (optional) */}
        <Route path="*"element={
          <NotFoundPage/>
        }></Route>
      </Routes>
    </Router>
  );
}

export default App;
