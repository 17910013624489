import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { addUOMs } from '../../../Controllers/UomsController';

function CreateUOM() {
  const [name, setName] = useState('');
  const [symbols, setSymbols] = useState(''); 
  const [cFactor, setCFactor] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [touched, setTouched] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('symbols', symbols);
    formData.append('cFoctor', cFactor);

    try {
      const response = await addUOMs(formData);

      // Check if the response is successful
      if (response.status === 200) {
        setMessage('UOM added successfully');
        
        // Reset form fields
        setName('');
        setSymbols('');
        setCFactor('');

        // Clear any existing error
        setError('');
      } else {
        setError('Failed to add uom');
      }
    } catch (err) {
      setError('Adding uom failed');
    }
  };

  const handleBlur = (field) => () => {
    setTouched({ ...touched, [field]: true });
  };

  const getFieldClass = (field) => {
    const fieldValues = { name, symbols, cFactor }; // Changed from password to phone

    return touched[field] && !fieldValues[field] ? 'is-invalid' : '';
  };


  return (
    <Container fluid className="d-flex min-vh-100">
      <Row className="justify-content-center align-items-center w-100">
        <Col xs={12} md={8} lg={6} className="px-4 py-2">
          <Card className="shadow-lg p-3">
            <Card.Body>
              <Card.Title className="text-center mb-4">Create Unit of Measurement</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formname" className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={handleBlur('name')}
                    className={`w-100 ${getFieldClass('name')}`}
                    required
                  />
                  {touched.name && !name && <Form.Text className="text-danger">Name is required</Form.Text>}
                </Form.Group>

                <Form.Group controlId="formSymbol" className="mb-3">
                  <Form.Label>Symbol</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter symbol"
                    value={symbols}
                    onChange={(e) => setSymbols(e.target.value)}
                    onBlur={handleBlur('symbols')}
                    className={`w-100 ${getFieldClass('symbols')}`}
                    required
                  />
                  {touched.symbols && !symbols && <Form.Text className="text-danger">Symbol is required</Form.Text>}
                </Form.Group>

                <Form.Group controlId="formCfactor" className="mb-3">
                  <Form.Label>Convertion Factor</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter convertion factor"
                    value={cFactor}
                    onChange={(e) => setCFactor(e.target.value)}
                    onBlur={handleBlur('cFactor')}
                    className={`w-100 ${getFieldClass('cFactor')}`}                  
                  />
                  {touched.cFactor && !cFactor && <Form.Text className="text-danger">Convertion factor is required</Form.Text>}
                </Form.Group> 

                <Button variant="primary" type="submit" className="w-100">
                  Save
                </Button>

                {message && (
                  <div className="text-center mt-3">
                    <p className="text-success">{message}</p>
                  </div>
                )}

                {error && <p className="text-danger mt-3">{error}</p>}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default CreateUOM;
