import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { updatePassword } from '../../Services/Auth/authService';
import { useParams } from 'react-router-dom'; // Import useParams

function ResetPassword() {
  const { id } = useParams(); // Retrieve ID from the URL
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(null);

  // Check if new passwords match
  useEffect(() => {
    if (newPassword && confirmNewPassword) {
      setPasswordMatch(newPassword === confirmNewPassword);
    } else {
      setPasswordMatch(null);
    }
  }, [newPassword, confirmNewPassword]);

  // Handle form submission for updating the password
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if new passwords match
    if (!passwordMatch) {
      setError('New passwords do not match');
      return;
    }

    try {
      const updatedData = {
        password: confirmNewPassword,
      };

      setError('');
      setMessage('');

      // Use the ID from the URL in updatePassword
      await updatePassword(id, updatedData);
      setMessage('Password reset successfully!');
    } catch (err) {
      setError('Failed to reset password');
    }
  };

  console.log(id);

  return (
    <Container fluid className="d-flex min-vh-100">
      <Row className="justify-content-center align-items-center w-100">
        <Col xs={12} md={8} lg={6}>
          <Card className="shadow-lg p-3">
            <Card.Body>
              <Card.Title className="text-center mb-4">Reset Password</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formNewPassword" className="mb-3">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className={`w-100 ${passwordMatch === false ? 'is-invalid' : ''} ${passwordMatch === true ? 'is-valid' : ''}`}
                    required
                  />
                  {passwordMatch === false && <Form.Text className="text-danger">Passwords do not match</Form.Text>}
                </Form.Group>

                <Form.Group controlId="formConfirmNewPassword" className="mb-3">
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm new password"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    className={`w-100 ${passwordMatch === false ? 'is-invalid' : ''} ${passwordMatch === true ? 'is-valid' : ''}`}
                    required
                  />
                  {passwordMatch === false && <Form.Text className="text-danger">Passwords do not match</Form.Text>}
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Reset Password
                </Button>
                {message && <p className="text-success mt-3">{message}</p>}
                {error && <p className="text-danger mt-3">{error}</p>}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ResetPassword;
