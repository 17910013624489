import axiosi from '../Services/axioz';
import global_URL from '../Const/urls';
import { branch, username } from '../Const/variables';

export const submitDailyReport = async (totalSalesManager,totalExpensesManager,totalLossesManager,totalPurchasedManager, totalProfitManager) => {
    try {
      const reportData = {
        totalSalesManager: parseInt(totalSalesManager, 10),
        totalExpensesManager: parseInt(totalExpensesManager, 10),
        totalLossesManager: parseInt(totalLossesManager, 10),
        totalPurchasedManager: parseInt(totalPurchasedManager, 10),
        totalProfitManager: parseInt(totalProfitManager, 10),
        username,
        branch,
      };
      

      // Send the data to the API via a POST request
      const response = await axiosi.post(`${global_URL}api/reports/submitDailySalesProfitLoss`, reportData);
      
      if (response.status === 200) {
        // Handle success (you can show a success message or redirect)
     
      }
    } catch (error) {
      console.error('Error submitting report:', error);
    }  // Close modal after submission
  };